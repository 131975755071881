'use client';

import 'swiper/css';
import { EllipsisHorizontalIcon, FlagIcon } from '@heroicons/react/24/outline';
import { Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@urql/next';
import { useContext } from 'react';
import { IdentityContext, PeerAccountContext, useAppAuth } from '~/app/_components';
import { SendMessage } from '~/app/_graphql';
import { appTrace, sendUserInteractionsTo } from '~/utils';
export function ReportUserMenu() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    isSignedIn
  } = useAppAuth();
  const {
    currentAccount
  } = useContext(IdentityContext);
  const [, executeSendMessage] = useMutation(SendMessage);
  async function sendReportUser() {
    const result = await executeSendMessage({
      recipientId: sendUserInteractionsTo,
      message: {
        content: `Report user ${account.id}. Sent from ${currentAccount?.id}`
      }
    });
    if (result.data?.sendMessage?.__typename === 'Account') {
      close();
      notifications.show({
        title: 'Report Sent',
        message: 'Thank you! We will look through your report.'
      });
    } else if (result?.data?.sendMessage?.__typename === 'Error') {
      appTrace('Could not report user', {
        result
      });
      notifications.show({
        title: 'Something went wrong, try again or contact us at hello@createwithdome.com',
        message: result.data.sendMessage.message,
        color: 'warning'
      });
    }
  }
  if (!isSignedIn) return null;
  return <Menu shadow="md" position="bottom" offset={-3} transitionProps={{
    transition: 'pop-top-right',
    duration: 150
  }} width="xl" data-sentry-element="Menu" data-sentry-component="ReportUserMenu" data-sentry-source-file="ReportUserMenu.tsx">
      <Menu.Target data-sentry-element="unknown" data-sentry-source-file="ReportUserMenu.tsx">
        <EllipsisHorizontalIcon height={25} color="gray" style={{
        cursor: 'pointer'
      }} data-sentry-element="EllipsisHorizontalIcon" data-sentry-source-file="ReportUserMenu.tsx" />
      </Menu.Target>

      <Menu.Dropdown data-sentry-element="unknown" data-sentry-source-file="ReportUserMenu.tsx">
        <Menu.Item leftSection={<FlagIcon height={20} />} onClick={sendReportUser} data-sentry-element="unknown" data-sentry-source-file="ReportUserMenu.tsx">
          Report User
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>;
}