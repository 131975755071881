export const onboardingPhrases = {
  signinTitle: 'Welcome to Dome\nthe producer in your pocket',
  registrationTitle: "Let's get your new profile setup",
  registrationTitle2: 'Hi, tell us about yourself',
  registrationButton1: 'Setup Profile',
  registrationButton2: 'Add Folio',
  folioTitle: 'Add your folio to showcase\nyour best work',
  folioTitleBrand: 'Add your folio to showcase\nyour brand',
  folioTitleAgency: 'Add your folio to showcase\nyour talent agency',
  folioButton: 'Explore Dome',
}
