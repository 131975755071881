'use client';

import { Card, Flex, ScrollArea, Text } from '@mantine/core';
import { ConnectButton } from '~/app/(accounts)/_components';
import { AccountAvatarBlockDektop } from '~/app/(accounts)/_components/desktop/AccountAvatarBlockDesktop';
// import { photogalleryScrollbar } from '~/app/(accounts)/accounts/styles.css'
import { IdentityContext, PeerAccountContext, StyledButton } from '~/app/_components';
import { ConnectionState } from '~/generated/graphql';
import { meRoute } from '~/utils';
import { ProfileDesktopContactInfo } from './ProfileDesktopContactInfo';
import { ProfilePhotoAlbum } from './ProfilePhotoAlbum';
import { useContext } from 'react';
function ActionButton() {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    account
  } = useContext(PeerAccountContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  if (isCurrentUserProfile) return <StyledButton type="black" text="EDIT PROFILE" href={meRoute().editProfile} width="100%" />;
  if (account.connectionStatus === ConnectionState.Connected) return <ProfileDesktopContactInfo />;
  return <ConnectButton data-sentry-element="ConnectButton" data-sentry-component="ActionButton" data-sentry-source-file="ProfileDesktop.tsx" />;
}
function ProfileWithFolio() {
  //const { currentAccount: account } = useContext(IdentityContext)
  const {
    account
  } = useContext(PeerAccountContext);
  if (!account) return null;
  return (
    // <Grid grow={true} overflow="hidden" px="100px" mah="calc(100vh - 70px)">
    <Flex px="100px" style={{
      minHeight: 'calc(100vh - 70px)'
    }} gap="100px" data-sentry-element="Flex" data-sentry-component="ProfileWithFolio" data-sentry-source-file="ProfileDesktop.tsx">
      <Flex w="30%" h={'calc(100vh - 100px)'} mah={'calc(100vh - 100px)'} data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
        <Flex direction="column" align="center"
        // justify="center"
        mt="100px" gap="lg" h="100%" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
          {/* <ScrollArea type="hover" offsetScrollbars={true} scrollbarSize="sm" mah="70%"> */}
          <Flex direction="column" align="center" justify="center" gap="30px"
          // h="50%"
          data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
            <AccountAvatarBlockDektop data-sentry-element="AccountAvatarBlockDektop" data-sentry-source-file="ProfileDesktop.tsx" />

            <ActionButton data-sentry-element="ActionButton" data-sentry-source-file="ProfileDesktop.tsx" />

            <Text size="lg" data-testid="about-desktop-section" data-sentry-element="Text" data-sentry-source-file="ProfileDesktop.tsx">
              {account.profile?.about}
            </Text>
          </Flex>
          {/* </ScrollArea> */}
        </Flex>
      </Flex>

      <Flex w="70%" justify="center" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
        <Flex align="center" justify="center" h="100%" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
          {account.folioItems && account.folioItems?.length >= 1 ?
          // <ScrollArea
          //   w="50%"
          //   type="always"
          //   h={'100vh'}
          //   // scrollbarSize={8}
          //   offsetScrollbars={true}
          //   scrollbars="y"
          //   color="black"
          //   // p="md"
          //   py={0}
          //   style={{
          //     position: 'absolute',
          //     top: '0',
          //     overflowX: 'clip',
          //   }}
          //   classNames={{
          //     scrollbar: photogalleryScrollbar,
          //   }}
          // >
          <div style={{
            minHeight: '200px',
            marginBottom: '10vh',
            marginTop: '120px'
          }}>
              <ProfilePhotoAlbum />
            </div>
          // </ScrollArea>
          : null}
        </Flex>
      </Flex>
    </Flex>
  );
}
function EmptyProfileForOwner() {
  const {
    account
  } = useContext(PeerAccountContext);
  return <Flex align="center" justify="center" mt="100px" gap="lg" h="100%" w="100%" data-sentry-element="Flex" data-sentry-component="EmptyProfileForOwner" data-sentry-source-file="ProfileDesktop.tsx">
      <Flex w="350px" direction="column" gap="xl" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
        <ScrollArea type="hover" offsetScrollbars={true} scrollbarSize="sm" mah="70%" data-sentry-element="ScrollArea" data-sentry-source-file="ProfileDesktop.tsx">
          <Flex direction="column" align="center" justify="center" gap="lg"
        // h="50%"
        data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
            <AccountAvatarBlockDektop centerTitle={true} data-sentry-element="AccountAvatarBlockDektop" data-sentry-source-file="ProfileDesktop.tsx" />

            <Text c="dimmed" style={{
            fontSize: '15px'
          }} data-sentry-element="Text" data-sentry-source-file="ProfileDesktop.tsx">
              {account.profile?.about}
            </Text>
          </Flex>
        </ScrollArea>
        <StyledButton type="black" text="EDIT PROFILE"
      // size={mobile ? 'medium' : 'small'}
      href={meRoute().editProfile} width="350px" data-sentry-element="StyledButton" data-sentry-source-file="ProfileDesktop.tsx" />
        <Card bg="#48FFB9"
      // w={mobile ? '80%' : undefined}
      p="md" radius="lg" mb="xl" data-sentry-element="Card" data-sentry-source-file="ProfileDesktop.tsx">
          <Flex direction="column" gap="md" justify="center" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktop.tsx">
            <Text ta="center" size="lg" data-sentry-element="Text" data-sentry-source-file="ProfileDesktop.tsx">
              Your profile is currently not visible to other users because it's missing folio images.
            </Text>
            <Text ta="center" size="lg" data-sentry-element="Text" data-sentry-source-file="ProfileDesktop.tsx">
              Click <span style={{
              fontWeight: 'bold'
            }}>EDIT PROFILE</span> to add some.
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>;
}
export function ProfileDesktop() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;

  // NOTE: showing profile even if no folios, but is not visible in community, search, or contacts
  const hasFolioItems = account.folioItems && account.folioItems.length > 0;
  if (isCurrentUserProfile && !hasFolioItems) return <EmptyProfileForOwner />;
  return <ProfileWithFolio data-sentry-element="ProfileWithFolio" data-sentry-component="ProfileDesktop" data-sentry-source-file="ProfileDesktop.tsx" />;
}