'use client';

import { Suspense, useContext, useEffect } from 'react';
import type { AnyStateMachine, StateFrom, StateValue } from 'xstate';
import { OnboardingContext } from '~/app/(me)/onboarding/_lib';
import { type Resource, appTrace, useSuspensefulGetter } from '~/utils';
import { NoSsr } from '../context';
function WaitForOnboardingInner({
  children,
  resource,
  context
}: {
  children: React.ReactNode;
  resource: Resource<StateValue>;
  context?: string;
}) {
  const value = resource.read();
  useEffect(() => {
    appTrace(`W4StableOnboardingState ${context}:${JSON.stringify(value)}`);
  }, [value, context]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return <>{children}</>;
}
export function stableOnboardingStateMatcher(state: StateFrom<AnyStateMachine>): boolean {
  return ['loading'].every(s => !state.matches(s));
}
export function WaitForStableOnboardingState({
  children,
  context
}: {
  children: React.ReactNode;
  context?: string;
}) {
  const onboardingActor = useContext(OnboardingContext);
  const resource = useSuspensefulGetter(onboardingActor, stableOnboardingStateMatcher, s => s.value);
  return <NoSsr data-sentry-element="NoSsr" data-sentry-component="WaitForStableOnboardingState" data-sentry-source-file="WaitForStableOnboardingState.tsx">
      <Suspense data-sentry-element="Suspense" data-sentry-source-file="WaitForStableOnboardingState.tsx">
        <WaitForOnboardingInner context={context} resource={resource} data-sentry-element="WaitForOnboardingInner" data-sentry-source-file="WaitForStableOnboardingState.tsx">
          {children}
        </WaitForOnboardingInner>
      </Suspense>
    </NoSsr>;
}