'use client';

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Card, Divider, Flex, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@urql/next';
import { useContext } from 'react';
import { SendMessage } from '~/app/_graphql';
import { appTrace, sendUserInteractionsTo } from '~/utils';
import { AppLayoutContext, IdentityContext } from '../context';
import { ActionBtn } from './styles.css';
type WarningBoxPropsType = {
  title: string;
  text: string;
  closeBox: () => void;
};
export function WarningBox({
  title,
  text,
  closeBox
}: WarningBoxPropsType) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const [, executeSendMessage] = useMutation(SendMessage);
  function calcIconSize() {
    return mobile ? 50 : 35;
  }
  async function sendDeleteAccount() {
    const result = await executeSendMessage({
      recipientId: sendUserInteractionsTo,
      message: {
        content: `Delete Account: ${currentAccount?.profile?.name} - ${currentAccount?.id}`
      }
    });
    if (result.data?.sendMessage?.__typename === 'Account') {
      closeBox();
      notifications.show({
        title: 'Sent',
        message: 'Sorry to see you leave!'
      });
    } else if (result?.data?.sendMessage?.__typename === 'Error') {
      appTrace('Could not accept the friendship request', {
        result
      });
      notifications.show({
        title: 'Something went wrong, try again or contact us at hello@createwithdome.com',
        message: result.data.sendMessage.message,
        color: 'warning'
      });
    }
  }
  const theme = useMantineTheme();
  return <Card p={0} data-sentry-element="Card" data-sentry-component="WarningBox" data-sentry-source-file="WarningBox.tsx">
      <Stack data-sentry-element="Stack" data-sentry-source-file="WarningBox.tsx">
        <Title order={6} p="sm" c="white" ta="center" style={{
        backgroundColor: '#FD7575'
      }} data-sentry-element="Title" data-sentry-source-file="WarningBox.tsx">
          {title}
        </Title>
        <Card data-sentry-element="Card" data-sentry-source-file="WarningBox.tsx">
          <Text fw={600} ta="center" data-sentry-element="Text" data-sentry-source-file="WarningBox.tsx">
            {text}
          </Text>
        </Card>

        <Divider data-sentry-element="Divider" data-sentry-source-file="WarningBox.tsx" />
        <Card pt={0} data-sentry-element="Card" data-sentry-source-file="WarningBox.tsx">
          <Flex justify="flex-end" gap={mobile ? 'xl' : 'md'} mr="md" data-sentry-element="Flex" data-sentry-source-file="WarningBox.tsx">
            <XCircleIcon height={calcIconSize()} width={calcIconSize()} onClick={() => closeBox()} className={ActionBtn} data-sentry-element="XCircleIcon" data-sentry-source-file="WarningBox.tsx" />

            <CheckCircleIcon color={theme.colors.brand[4]} height={calcIconSize()} width={calcIconSize()} onClick={sendDeleteAccount} className={ActionBtn} data-sentry-element="CheckCircleIcon" data-sentry-source-file="WarningBox.tsx" />
          </Flex>
        </Card>
      </Stack>
    </Card>;
}