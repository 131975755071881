import { publicRuntimeConfig } from './config'

const isSSR = typeof window === 'undefined'

const enforceTracing = publicRuntimeConfig.logLevel === 'TRACE'
const deployment = publicRuntimeConfig.deployment

export const ENABLE_TRACING_FLAG = 'enableTracing'
export const ENABLE_GRAPHQL_DELAY_FLAG = 'enableGraphqlDelay'
export const ENABLE_XSTATE_INSPECTOR_FLAG = 'enableXstateInspector'

export function fetchFlag(flag: string, defaultValue = false) {
  if (isSSR) return process.env[flag] === 'true'

  const item = localStorage.getItem(flag)
  // console.log('fetchFlag', { flag, defaultValue, item })
  if (!item) return defaultValue
  const parsed = !!JSON.parse(item)
  // console.log('fetchFlag', { flag, defaultValue, item, parsed })
  return parsed
}
export function isTracingEnabled() {
  if (enforceTracing) return true
  if (isSSR) return false
  return fetchFlag(ENABLE_TRACING_FLAG)
}

export function isGraphqlDelayEnabled() {
  if (deployment === 'stable') return false
  return fetchFlag(ENABLE_GRAPHQL_DELAY_FLAG)
}
