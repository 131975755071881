import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.4.1_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2Fprojects%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42UQY%2FaMBCF7%2FwKV9VKSyWjJN2wJdyotCqHlSpRqUfkxJPg4tiW7XShVf97jZM0JoDoCWFn3vv8ZuyZsUcOZksoXTXWSrHdpou0TusI%2FZ4gVDTaSJ0hJZmwoJeTP5NZV0HB7K1UX7X8AYX9TDTtS2NfugNW7WyG5mmkDku3kJNiX2nZCIoLyU%2BqTOxAMxuqqlZuY4mFFaEV9KJJy9MW5txpXYHZAHfFQK9AffT1NRO4B4vvgqF3rFZSWyLOGGuZMw6Bx6Zd75yeWidywG%2BM2l2GFumDt5Gagsa5dDHXzl4dkJGcUfST6EeMa%2BfCBLQIuNLkiOPpMgiSNFYuvbKumMhQ1K0MYIV0TRJ247CYFK8es6dKO6pT7T%2BGgvDiMZ4lqYYafRiBGLcL0%2BnVk5fu57smSoHuDebeoDtyHEUPYSGXBTkxrYVq7KjwOSTjULqjJr4zwcEciXDhfQFCh8JPoeNT2jazT6v9f1ci48RYLEtsjwouQUYi3XT6U7y4sHuZha8s3Qo27Be43szSuU81rK40o2shBncynpRxbLWkhH9jdmhjPnZKEjdH4Zx2u29dDs9RdGOMKeyNuzOvFx7F4HFL5R6Ccu8JE1WXYpze%2FMABXNkfJfDCgNMzRDqOIZ7%2Fr0Tca8DdKAcNFsztyt%2FjXqX0Ku3dDi%2F1%2B%2Bgisu4B0ISyxpxm5GJCNJTrmlRgfFN6i%2BrsPT3NyDnlXwaGCWHEBQAA%22%7D"
export var addButton = 'styles_addButton__595m5m0';
export var calenderHeader = 'styles_calenderHeader__595m5m8';
export var contentSectionMobile = 'styles_contentSectionMobile__595m5m5';
export var dekstopModalTitle = 'styles_dekstopModalTitle__595m5mc';
export var desktopProjectCard = 'styles_desktopProjectCard__595m5m1';
export var desktopSelectedProjectCard = 'styles_desktopSelectedProjectCard__595m5m3';
export var gridInner = 'styles_gridInner__595m5ma';
export var inputWrapperBorder = 'styles_inputWrapperBorder__595m5mf';
export var locationInputWrapper = 'styles_locationInputWrapper__595m5m7';
export var mobileProfileWrapper = 'styles_mobileProfileWrapper__595m5m6';
export var mobileProjectCardStyles = 'styles_mobileProjectCardStyles__595m5m4';
export var modalFieldTitle = 'styles_modalFieldTitle__595m5md';
export var modalFieldTitle1 = 'styles_modalFieldTitle1__595m5me';
export var modalTitle = 'styles_modalTitle__595m5mb';
export var projectInputFont = 'styles_projectInputFont__595m5m9';
export var projectStateBadge = 'styles_projectStateBadge__595m5m2';
export var refImagesModal = 'styles_refImagesModal__595m5mg';