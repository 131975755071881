'use client';

import { useEffect, useState } from 'react';
export const useWindowHeight = () => {
  const isSSR = typeof window === 'undefined';
  const [height, setHeight] = useState(isSSR ? 0 : window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return height;
};