import { produce } from 'immer';

/// important -  avoid problems with import loops
import { inspectFolioItems } from '~/app/(me)/onboarding/_lib/debug/folio';
import { graphql } from '~/generated/gql';
import type { CurrentAccountFolioQuery, GraphCacheConfig } from '~/generated/graphql';

// this query must not be located with components - breaks jest
export const currentAccountFolio = graphql( /* GraphQL */`
  query currentAccountFolio {
    currentAccount {
      __typename
      ... on Account {
        ...TrimmedAccountWithContactInfo
      }
      ... on Error {
        message
      }
    }
  }
`);
export const optimsticUpdaters: GraphCacheConfig['optimistic'] = {
  updateCurrentAccount(args, cache, _info) {
    const current = cache.readQuery<CurrentAccountFolioQuery>({
      query: currentAccountFolio
    });
    if (current?.currentAccount?.__typename === 'Account' && current.currentAccount.folioItems) {
      const folioItems = produce(current.currentAccount.folioItems, currentItems => {
        inspectFolioItems('optimistic folio update (before)', currentItems);
        console.log('received folio args:', args);
        currentItems.forEach(currentItem => {
          const fi = args.account.folioItems?.update?.find(fi => fi?.where?.id === currentItem.id);
          if (typeof fi?.data?.order === 'number') {
            console.log('updating order', currentItem.id, fi.data.order);
            currentItem.order = fi.data.order;
          }
        });
      });
      inspectFolioItems('optimistic folio update (after)', folioItems);
      return {
        __typename: 'Account',
        folioItems
      };
    }
    return {
      __typename: 'Account'
    };
  }
};