// COMPONENT NOT USED

import { Divider, Grid, Switch } from '@mantine/core';
import { useContext } from 'react';
import { ProfessionsFilterContext } from '~/app/(accounts)/_components';
import { toggleProfession } from '~/app/(accounts)/_lib';
import { RemoteConfigContext } from '~/app/_components';

// NOT USED
export function ProfessionsFilterMobile() {
  const {
    professions: allProfessions
  } = useContext(RemoteConfigContext);
  const {
    professions,
    setProfessions
  } = useContext(ProfessionsFilterContext);
  return <Grid gutter="sm" data-sentry-element="Grid" data-sentry-component="ProfessionsFilterMobile" data-sentry-source-file="professionsFilterMobile.tsx">
      {allProfessions.map((p, i) => <Grid.Col key={i} span={{
      xs: 12,
      sm: 6
    }}>
          <Switch color="brand" label={p.name} checked={professions.includes(p.id)} onChange={event => toggleProfession({
        event,
        id: p.id,
        professions,
        setProfessions
      })} />
          <Divider mt="xs" />
        </Grid.Col>)}
    </Grid>;
}