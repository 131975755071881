'use client';

import React, { createContext } from 'react';
import { Profession } from '~/generated/graphql';
export type RemoteConfigContextType = {
  professions: Profession[];
};
export const RemoteConfigContext = createContext<RemoteConfigContextType>({
  professions: []
});
export function RemoteConfigProvider({
  children,
  professions
}: React.PropsWithChildren<RemoteConfigContextType>) {
  return <RemoteConfigContext.Provider value={{
    professions
  }} data-sentry-element="unknown" data-sentry-component="RemoteConfigProvider" data-sentry-source-file="RemoteConfigContext.tsx">{children}</RemoteConfigContext.Provider>;
}
export function RemoteConfigProviderStorybook({
  children
}: React.PropsWithChildren) {
  const professions = Array.from({
    length: 10
  }, (_, index) => ({
    name: `profession${index}`,
    id: index.toString()
  }));
  return <RemoteConfigContext.Provider value={{
    professions
  }} data-sentry-element="unknown" data-sentry-component="RemoteConfigProviderStorybook" data-sentry-source-file="RemoteConfigContext.tsx">{children}</RemoteConfigContext.Provider>;
}