import { graphql } from '~/generated/gql'

export const updateCurrentAccount = graphql(/* GraphQL */ `
  mutation updateAccountForOnboarding($account: AccountUpdateInput!) {
    updateCurrentAccount(account: $account) {
      __typename
      ... on Account {
        ...TrimmedCurrentAccount
        ...FullUnreadNotifications
      }
      ... on Error {
        message
      }
    }
  }
`)
