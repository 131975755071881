import { graphql } from '~/generated/gql'

// WARNING we can not request any non-public fields there because the backend
// does not support it
// When this request starts there is no JWT token and no account id associated
// with it so authentication checks fail on the contactInfo fields
// If there is a need to fetch account.contactInfo - it needs to be done with a proper
// account id in the JWT token
export const createCurrentUser = graphql(/* GraphQL */ `
  mutation createCurrentUserOnboardingForm(
    $user: UserCreateInput!
    $auth0token: String!
  ) {
    createCurrentUser(user: $user, auth0token: $auth0token) {
      __typename
      ... on CurrentUserWithToken {
        user {
          ...TrimmedCurrentUser
        }
        account {
          ...TrimmedCurrentAccount
        }
        token
      }
      ... on Error {
        message
      }
    }
  }
`)
