'use client';

import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'; // Import ArrowDownCircleIcon instead of ArrowUpCircleIcon
import { Affix, Button, Transition, rem } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { useEffect, useState } from 'react';
export function ScrollToBottom() {
  const [scroll, scrollTo] = useWindowScroll();
  const [isAtBottom, setIsAtBottom] = useState(false);
  useEffect(() => {
    const maxScrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    setIsAtBottom(scroll.y >= maxScrollHeight);
  }, [scroll.y]);
  const handleScrollToBottom = () => {
    const maxScrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    scrollTo({
      y: maxScrollHeight
    });
  };
  return <Affix position={{
    bottom: rem(2)
  }} data-sentry-element="Affix" data-sentry-component="ScrollToBottom" data-sentry-source-file="ScrollToBottomAffix.tsx">
      <Transition transition="slide-up" mounted={scroll.y > 20 && !isAtBottom} data-sentry-element="Transition" data-sentry-source-file="ScrollToBottomAffix.tsx">
        {transitionStyles => <Button color="var(--mantine-color-gray-7)" ml="md" p="0" variant="subtle" style={{
        ...transitionStyles,
        height: '100px',
        marginBottom: '0'
      }} onClick={handleScrollToBottom} // Call handleScrollToBottom function when the button is clicked
      >
            <ArrowDownCircleIcon // Change the icon to ArrowDownCircleIcon
        width={60} style={{
          opacity: 0.8
        }} />
          </Button>}
      </Transition>
    </Affix>;
}