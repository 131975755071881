'use client';

import { Flex, Title } from '@mantine/core';
import { AppShellContent } from '~/app/_components';
export function RequireRegistration() {
  return <AppShellContent fullHeight variant="branded" data-sentry-element="AppShellContent" data-sentry-component="RequireRegistration" data-sentry-source-file="RequireRegistration.tsx">
      <Flex gap="xl" justify="center" direction="column" align="center" h="100%" data-sentry-element="Flex" data-sentry-source-file="RequireRegistration.tsx">
        <Title order={3} data-sentry-element="Title" data-sentry-source-file="RequireRegistration.tsx">Register to access this content</Title>
      </Flex>
    </AppShellContent>;
}