/* eslint-disable @typescript-eslint/no-empty-function */
'use client';

import type React from 'react';
import { createContext, useMemo, useState } from 'react';
export type ProfessionsFilterContextType = {
  professions: string[];
  setProfessions: (professions: string[]) => void;
};
const ctxDefault: ProfessionsFilterContextType = {
  professions: [],
  setProfessions: () => {}
};
export const ProfessionsFilterContext = createContext<ProfessionsFilterContextType>(ctxDefault);
export function ProfessionsFilterProvider({
  children
}: React.PropsWithChildren) {
  const [professions, setProfessions] = useState<string[]>(ctxDefault.professions);
  const values = useMemo(() => ({
    professions,
    setProfessions
  }), [professions, setProfessions]);
  return <ProfessionsFilterContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="ProfessionsFilterProvider" data-sentry-source-file="ProfessionsFilterContext.tsx">{children}</ProfessionsFilterContext.Provider>;
}