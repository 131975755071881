'use client';

// import Link from 'next/link'
// import { useContext } from 'react'

// import { AppLayoutContext } from '../../context'
import { SafeImage } from '../../images';
export function HeaderLogoOnboarding() {
  // const { mobile } = useContext(AppLayoutContext)

  return (
    // <Link href="/">
    <SafeImage src="/imgs/layout/domeSphereLogo2.webp" priority={true} alt="Dome logo" height={73} width={128} data-sentry-element="SafeImage" data-sentry-component="HeaderLogoOnboarding" data-sentry-source-file="LogoOnboarding.tsx" />
    // </Link>
  );
}