import { mostReadable } from '@ctrl/tinycolor'
import { Button, DEFAULT_THEME, type MantineThemeOverride } from '@mantine/core'

const _getReadableColor = ({
  color,
  colorVariants = ['#fff', '#000'],
  isEnabled = true,
}: {
  color: string
  colorVariants: string[]
  isEnabled: boolean
}): string | undefined => (isEnabled ? mostReadable(color, colorVariants)?.toHexString() : color)

export const globalTheme: MantineThemeOverride = {
  fontFamily: 'Poppins, sans-serif',
  // fontFamilyMonospace: 'Monaco, Courier, monospace',
  // headings: {
  //   // Use default theme if you want to provide default Mantine fonts as a fallback
  //   fontFamily: `${greycliffCF.style.fontFamily}, ${DEFAULT_THEME.fontFamily}`,
  // },
  autoContrast: true,

  headings: {
    sizes: {
      h1: { fontSize: '2.5rem', lineHeight: '2.5rem', fontWeight: '700' },
      h2: { fontSize: '1.375rem', lineHeight: '1.64rem', fontWeight: '700' },
      h3: { fontSize: '1.125rem', lineHeight: '1.03rem', fontWeight: '600' },
      h4: { fontSize: '0.976rem', lineHeight: '0.988rem', fontWeight: '600' },
      h5: { fontSize: '0.75rem', lineHeight: '0.75rem', fontWeight: '600' },
      h6: { fontSize: '0.5625rem', lineHeight: '0.75rem', fontWeight: '600' },
    },
  },

  // fontSizes: DEFAULT_THEME.fontSizes,
  fontSizes: {
    xs: '6px',
    sm: '0.5625rem', //p4
    md: '0.75rem', // p3
    lg: '0.875rem', // p2
    xl: '0.975rem', //p1
    xxl: '1.5rem',
  },

  lineHeights: {
    xs: 'normal',
    sm: 'normal', // p4
    // md: '166.7%', // p3
    md: 'normal', // p3
    lg: 'normal', // p2
    xl: 'normal', // p1
    xxl: 'normal',
  },

  primaryShade: 4,
  colors: {
    brand: [
      '#E9F6F1',
      '#C6EDDE',
      '#A1EACE',
      '#78F0C2',
      '#48FFB9',
      '#3DE9A7',
      '#36D397',
      '#3AB486',
      '#3F9776',
      '#418068',
    ],

    project: [
      '#C1D5DA',
      '#A4C8D1',
      '#87BFCD',
      '#67B9CE',
      '#43B8D6',
      '#1ABCE6',
      '#00B9E8',
      '#159ABC',
      '#22829A',
      '#296E80',
    ],

    // Using defaults from v7
    gray: [
      '#f8f9fa',
      '#f1f3f5',
      '#e9ecef',
      '#dee2e6',
      '#ced4da',
      '#adb5bd',
      '#868e96',
      '#495057',
      '#343a40',
      '#212529',
    ],
  },
  primaryColor: 'brand',

  radius: DEFAULT_THEME.radius,

  spacing: DEFAULT_THEME.spacing,

  breakpoints: {
    ...DEFAULT_THEME.breakpoints,
    xxl: '110em',
  },

  components: {
    Button: {
      ...DEFAULT_THEME,
      defaultProps: {
        radius: '10px',
        outline: 'none',
      },
    },
  },

  // components: {
  //   Button: Button.extend({
  //     defaultProps: {
  //       radius: '10px'
  //     },
  //   }),
  // },

  // https://github.com/mantinedev/mantine/discussions/1727
  // components: {
  //   Button: {
  //     styles: (
  //       theme: MantineTheme,
  //       styleParams,
  //       //styleParams: ButtonStylesParams,
  //       contextParams,
  //     ) => {
  //       const colorPalette =
  //         theme.colors[styleParams.color || theme.primaryColor]
  //       if (!colorPalette) return { root: {} }
  //       const color = colorPalette[theme.primaryShade as number]
  //       const betterColor = getReadableColor({
  //         color,
  //         colorVariants: ['#fff', '#000'],
  //         isEnabled: true,
  //       })
  //       return {
  //         root: {
  //           color: contextParams.variant === 'filled' ? betterColor : undefined,
  //         },
  //       }
  //     },
  //   },
  //   SegmentedControl: {
  //     styles: (
  //       theme: MantineTheme,
  //       styleParams: SegmentedControlStylesParams,
  //     ) => {
  //       const colorPalette =
  //         theme.colors[styleParams.color || theme.primaryColor]
  //
  //       const color = colorPalette[theme.primaryShade as number]
  //
  //       const betterColor = getReadableColor({
  //         color,
  //         colorVariants: ['#fff', '#000'],
  //         isEnabled: true,
  //       })
  //
  //       return {
  //         label: {
  //           '&[data-active],&[data-active]:hover': {
  //             //color: contextParams.variant === 'filled' ? betterColor : undefined,
  //             color: betterColor,
  //           },
  //         },
  //       }
  //     },
  //   },
  //
  //   NavLink: {
  //     // styles: (
  //     //   theme: MantineTheme,
  //     //   styleParams: ButtonStylesParams,
  //     //   contextParams
  //     // ) => {
  //     //   const colorPalette =
  //     //     theme.colors[styleParams.color || theme.primaryColor]
  //     //   const color = colorPalette[theme.primaryShade as number]
  //     //   const readable = getReadableColor({
  //     //     color,
  //     //     colorVariants: ['#fff', '#000'],
  //     //     isEnabled: true,
  //     //   })
  //     //   console.log('navlink override color', { color, readable })
  //     //   return {
  //     //     root: {
  //     //       color:
  //     //         contextParams.variant === 'filled'
  //     //           ? getReadableColor({
  //     //               color,
  //     //               colorVariants: ['#fff', '#000'],
  //     //               isEnabled: true,
  //     //             })
  //     //           : undefined,
  //     //     },
  //     //   }
  //     // },
  //   },
  //   Tabs: {
  //     styles: (
  //       theme: MantineTheme,
  //       styleParams: ButtonStylesParams,
  //       contextParams,
  //     ) => {
  //       const colorPalette =
  //         theme.colors[styleParams.color || theme.primaryColor]
  //       const color = colorPalette[theme.primaryShade as number]
  //       //   // const readable = getReadableColor({
  //       //   //   color,
  //       //   //   colorVariants: ['#fff', '#000'],
  //       //   //   isEnabled: true,
  //       //   // })
  //       //   // console.log('navlink override color', { color, readable })
  //       return {
  //         root: {
  //           color:
  //             contextParams.variant === 'filled'
  //               ? getReadableColor({
  //                 color,
  //                 colorVariants: ['#fff', '#000'],
  //                 isEnabled: true,
  //               })
  //               : undefined,
  //         },
  //       }
  //     },
  //   },
  // Checkbox: {
  //   // Subscribe to theme and component params
  //   styles: (theme: MantineTheme, params: ButtonStylesParams) => ({
  //     icon: {
  //       color: getReadableColor({
  //         color: theme.colors[params.color || theme.primaryColor][theme.primaryShade],
  //         isEnabled: theme.enableReadableOppositeColors,
  //       }),
  //     },
  //   }),
  // },
  // Radio: {
  //   // Subscribe to theme and component params
  //   styles: (theme: MantineTheme, params: ButtonStylesParams) => ({
  //     icon: {
  //       color: getReadableColor({
  //         color: theme.colors[params.color || theme.primaryColor][theme.primaryShade],
  //         isEnabled: theme.enableReadableOppositeColors,
  //       }),
  //     },
  //   }),
  // },
}
