'use client';

import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import type React from 'react';
import { createContext } from 'react';
import type { Actor } from 'xstate';
import { authMachine, authMachineCtx, startAppMachine } from '~/machines';
import { publicRuntimeConfig } from '~/utils';
import { AuthRouter } from './AuthRouter';
export type AuthActorContextType = {
  actor: Actor<typeof authMachine>;
};
export const AuthActorContext = createContext<AuthActorContextType>({} as AuthActorContextType);
const actor = startAppMachine({
  machine: authMachine,
  debugger: authMachineCtx.debugger
});
export function AuthProvider({
  children
}: React.PropsWithChildren) {
  const auth0Config: Auth0ProviderOptions = {
    domain: publicRuntimeConfig.auth0.domain,
    clientId: publicRuntimeConfig.auth0.clientID,
    authorizationParams: {
      audience: publicRuntimeConfig.auth0.audience,
      display: 'popup',
      redirect_uri: typeof window !== 'undefined' ? window.location.origin : undefined
    }
  };
  return <Auth0Provider {...auth0Config} data-sentry-element="Auth0Provider" data-sentry-component="AuthProvider" data-sentry-source-file="AuthProvider.tsx">
      <AuthActorContext.Provider value={{
      actor
    }} data-sentry-element="unknown" data-sentry-source-file="AuthProvider.tsx">
        <AuthRouter data-sentry-element="AuthRouter" data-sentry-source-file="AuthProvider.tsx">{children}</AuthRouter>
        <a style={{
        display: 'none'
      }} data-testid="auth0-token-send" onClick={() => {
        console.error('auth0-token-send: must be used on CI only');
        const timestamp = new Date().toISOString();
        actor.send({
          type: 'AUTH0_TOKEN_UPDATE',
          data: {
            token: `${timestamp}@example.com`
          }
        });
      }}>
          Testing only
        </a>
      </AuthActorContext.Provider>
    </Auth0Provider>;
}