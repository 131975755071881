import { graphql } from '~/generated'

export const SendNotification = graphql(/* GraphQL */ `
  mutation sendNotification(
    $id: String!
    $content: String!
    $title: String
    $url: String
  ) {
    sendNotification(
      recipientId: $id
      content: $content
      title: $title
      url: $url
    ) {
      __typename
      ... on Account {
        id
        messages {
          id
          recipientId
          content
        }
        connectionStatus
      }
      ... on Error {
        message
      }
    }
  }
`)
