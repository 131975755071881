import { HeartIcon as HeartOutline, PencilIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartSolid } from '@heroicons/react/24/solid';
import { Flex, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { usePathname } from 'next/navigation';
import { useContext, useState } from 'react';
import { IdentityContext, PeerAccountContext } from '~/app/_components';
import type { FolioItem } from '~/generated/graphql';
import { CaptionModalInner } from '../desktop/CaptionModalInner';
import { useLikeHandler } from '../desktop/useLikeImageHandler';
import { ReportUserMenu } from '~/app/(accounts)/_components';
export function MobileLikeImage({
  image,
  id
}: {
  image: FolioItem;
  id: string;
}) {
  const pathname = usePathname();
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  const [selectedImage, setSelectedImage] = useState<FolioItem | undefined>(undefined);
  const [opened, {
    open: captionOpen,
    close: captionClose
  }] = useDisclosure(false);
  function handleBadgeOnClick(item: FolioItem) {
    setSelectedImage(item);
    captionOpen();
  }
  const {
    solidHeart,
    handleLikePhotoOnClick,
    handleUnLikeOnClick
  } = useLikeHandler({
    hasLiked: pathname.includes('/accounts') && image.hasLiked
  });
  return <Flex w="100%" justify="flex-start" align="center" gap="sm" style={{
    cursor: 'pointer'
  }} data-sentry-element="Flex" data-sentry-component="MobileLikeImage" data-sentry-source-file="MobileLikeImage.tsx">
      {!isCurrentUserProfile ? <Flex justify="space-between" w="100%">
          {solidHeart ? <HeartSolid height={25} color="black" onClick={() => handleUnLikeOnClick(id)} /> : <HeartOutline height={25} color="black" onClick={() => handleLikePhotoOnClick(id)} />}
          <Text c="dimmed" size="xs" maw="80%" style={{
        zIndex: '1000'
      }}>
            {image.caption}
          </Text>
          {currentAccount ? <ReportUserMenu /> : null}
        </Flex> : <>
          <Flex justify="space-between" align="center" w="100%">
            <Flex align="center" gap="md">
              {/* <Flex align="center" gap="xs">
                <HeartSolid height={20} color="black" />
                <Text size="10px">{image.totalCountLikes}</Text>
               </Flex> */}

              <Text c="dimmed" size="md" style={{
            zIndex: '1000'
          }}>
                {image.caption}
              </Text>
            </Flex>
            <Flex miw="10%" justify="flex-end">
              <PencilIcon height={18} onClick={() => handleBadgeOnClick(image)} />
            </Flex>
          </Flex>

          <Modal opened={opened} onClose={() => {
        setSelectedImage(undefined);
        captionClose();
      }} withOverlay={false} withCloseButton={false}
      // closeOnClickOutside
      // overlayProps={{ backgroundOpacity: 0.8, blur: 0.9, color: 'black' }}
      >
            <CaptionModalInner selectedImage={selectedImage} setSelected={setSelectedImage} closeCaption={captionClose} />
          </Modal>
        </>}
    </Flex>;
}