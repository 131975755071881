import { graphql } from '~/generated/gql'
import { configureUrqlClient } from '~/graphql/browserClient'
import { authMachineCtx } from '../actor'
import type { AuthContext } from '../context'

const authenticateMutation = graphql(/* GraphQL */ `
  mutation GenerateToken($auth0token: String) {
    authenticate(auth0token: $auth0token) {
      __typename
      ... on CurrentUserWithToken {
        user {
          ...TrimmedCurrentUser
        }
        account {
          ...TrimmedCurrentAccount
        }
        token
      }
      ... on Error {
        message
      }
    }
  }
`)

export async function authenticate(args: { context: AuthContext }) {
  const ctx = args.context
  const md = authMachineCtx.debugger
  if (!(ctx.auth0token || ctx.accessToken?.value)) {
    throw new Error('missing auth0 or api token')
  }
  md.consoleLog('executing authenticate service')
  const { client } = configureUrqlClient({
    apiToken: ctx.accessToken.value || undefined,
  })
  md.consoleLog('new client created')
  const result = await client.mutation(authenticateMutation, { auth0token: ctx.auth0token }).toPromise()
  md.consoleLog(`new result:${result.data?.__typename}`)
  if (result.data?.authenticate?.__typename === 'CurrentUserWithToken') {
    md.consoleLog(`authenticated user:${result.data.authenticate.user.id}`)
    return {
      user: result.data.authenticate.user,
      account: result.data.authenticate.account,
      token: result.data.authenticate.token,
    }
  }
  if (result.data?.authenticate?.__typename === 'Error') {
    throw new Error(result.data.authenticate.message)
  }
  throw new Error(`unhandled error: ${result}`)
}
