'use client';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';
//import '@uppy/webcam/dist/style.min.css'

import { captureException } from '@sentry/nextjs';
import AwsS3 from '@uppy/aws-s3';
import Uppy, { type UppyFile } from '@uppy/core';
import ImageEditor from '@uppy/image-editor';
import { DashboardModal, FileInput } from '@uppy/react';
import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import './styles.css';
import { type FileCreateInput, FileService, type FileType } from '~/generated/graphql';
import { appTrace, publicRuntimeConfig } from '~/utils';
import { AppLayoutContext } from '../context';
const isServerSide = typeof window === 'undefined';
export type UploadedFileData = ReturnType<typeof fileData>;
type ImageUploaderProps = {
  addCaption?: boolean;
  setCaption?: Dispatch<SetStateAction<string | undefined>>;
  onChange: (file: FileCreateInput) => void;
  open: boolean;
  onClose: () => void;
  fileType: FileType;
  maxNumberOfFiles?: number;
  shouldCloseAfterFinish: boolean;
};

// TODO type is assigned on backend depending on relationships
// maybe we will need to keep it here - dunno
function fileData(file: UppyFile<Meta, Body>, body: Body, _type: FileType): FileCreateInput {
  appTrace('fileData', JSON.stringify(file), JSON.stringify(body));
  return {
    key: extractFilename(body.location!)!,
    service: FileService.MinioMedia,
    size: file.size!,
    name: file.name!,
    mimeType: file.type as string
  };
}

// If the name is not normalised the avatar URLs break in email clients
function normalizeFileName(fileName: string) {
  return fileName.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9_\-.]/gi, '');
}
function extractFilename(urlString: string): string | null {
  try {
    const url = new URL(urlString);
    const pathname = url.pathname;
    const filename = pathname.split('/').pop();

    // Return filename if it exists, otherwise return null
    return filename || null;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}
type Meta = {
  caption: string;
  key: string;
};
type Body = {
  etag: string;
  location: string;
};
export function ImageUploader({
  addCaption,
  setCaption,
  onChange,
  open = false,
  onClose,
  fileType,
  maxNumberOfFiles = 1,
  shouldCloseAfterFinish
}: ImageUploaderProps) {
  const {
    mobile
  } = useContext(AppLayoutContext);

  // const searchParams = useSearchParams()
  // const [isFeatureEnabled, setIsFeatureEnabled] = useState(false)

  // useEffect(() => {
  //   // Check if the query parameter exists in the search params
  //   const showInstagramUpload = searchParams.get('showInstagramUpload')
  //   if (showInstagramUpload === 'true') {
  //     setIsFeatureEnabled(true)
  //   }
  // }, [])

  const uppy = useMemo(() => {
    function createUppy() {
      return new Uppy<Meta, Body>({
        id: fileType.toString(),
        // autoProceed: mobile,
        allowMultipleUploadBatches: false,
        restrictions: {
          allowedFileTypes: ['image/jpeg', 'image/png', 'image/webp'],
          minNumberOfFiles: 1,
          maxNumberOfFiles,
          maxFileSize: 90 * 1024 * 1024,
          maxTotalFileSize: 90 * 1024 * 1024
        }
      });
    }
    const uppyInstance = createUppy();
    if (!isServerSide) {
      configureUppy(uppyInstance, fileType);
      uppyInstance.on('file-removed', file => {
        appTrace('file-removed', file);
        onChange(null!);
      });
      uppyInstance.on('upload-success', (file, response) => {
        appTrace('upload-success', file, response);
        if (file) {
          if (setCaption) setCaption(file.meta.caption);
          onChange(fileData(file, response.body!, fileType));
        }
      });
      uppyInstance.on('upload-error', file => {
        appTrace('upload-error', file);
        captureException('upload-error', file);
        if (file) uppyInstance.removeFile(file.id);
      });
    }
    return uppyInstance;
  }, [fileType, onChange, maxNumberOfFiles, setCaption]);
  const inputDivId = `${uppy.getID()}-file-input`;
  // useEffect(() => {
  //   if (!mobile) return
  //   if (open) {
  //     if (maxNumberOfFiles === 0) throw new Error('maxNumberOfFiles is reached')
  //     const inputElement = document.querySelector(
  //       `#${inputDivId} input`,
  //     ) as HTMLInputElement | null

  //     if (inputElement) {
  //       inputElement.click()
  //     } else {
  //       throw new Error('Input element not found')
  //     }
  //   }
  //  }, [open, mobile])

  // if (mobile)
  //   return (
  //     <div id={inputDivId} style={{ display: 'none' }}>
  //       <FileInput uppy={uppy} />
  //     </div>
  //   )

  return <>
      <DashboardModal uppy={uppy} plugins={['ImageEditor', 'Instagram']} style={{
      width: '100%'
    }} doneButtonHandler={() => {
      console.log('buttonhandler');
      onClose();
    }} metaFields={addCaption ? [{
      id: 'caption',
      name: 'Image Caption (optional)'
    }] : []} showProgressDetails={false} autoOpen={addCaption ? 'metaEditor' : undefined} showRemoveButtonAfterComplete closeAfterFinish={shouldCloseAfterFinish} proudlyDisplayPoweredByUppy={false} open={open} closeModalOnClickOutside onRequestClose={onClose} hideCancelButton={mobile} data-sentry-element="DashboardModal" data-sentry-source-file="ImageUploader.tsx" />
    </>;
}
function configureUppy(uppy: Uppy<Meta, Body>, _fileType: FileType) {
  const companionUrl = publicRuntimeConfig.api.companion;
  uppy.use(AwsS3, {
    endpoint: companionUrl
  });
  uppy.use(ImageEditor, {
    quality: 0.8,
    cropperOptions: {
      viewMode: 1,
      background: false,
      autoCropArea: 1,
      responsive: true,
      croppedCanvasOptions: {}
    },
    actions: {
      revert: true,
      rotate: true,
      granularRotate: true,
      flip: true,
      zoomIn: true,
      zoomOut: true,
      cropSquare: true,
      cropWidescreen: true,
      cropWidescreenVertical: true
    }
  });
  // isFeatureEnabled ? uppy.use(Instagram, { companionUrl: companionUrl }) : null
  // uppy.use(Instagram, { companionUrl: companionUrl })
}