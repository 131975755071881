import { produce } from 'immer'

import type { Error, TrimmedAccountWithContactInfoFragment } from '~/generated/graphql'

export function sortedFolioItems(account?: Partial<TrimmedAccountWithContactInfoFragment> | Partial<Error> | null) {
  if (account?.__typename === 'Account') {
    const folioItems = account?.folioItems
      ? produce(account.folioItems || [], (items) => items.sort((a, b) => a.order - b.order))
      : []
    return folioItems
  }
  return []
}
