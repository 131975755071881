import { mapExchange } from '@urql/next'

import type { UrqlClientOpts } from '../browserClient'

export function authErrorExchange(opts: UrqlClientOpts) {
  return mapExchange({
    onError(error) {
      const isAuthError = error.graphQLErrors.some((e) => e.extensions?.code === 'NOT_AUTHENTICATED')
      if (isAuthError && opts.authActor) opts.authActor.send({ type: 'GRAPHQL_UNAUTHENTICATED' })
    },
  })
}
