'use client';

import { Card, Flex, ScrollArea, Text } from '@mantine/core';
import { ConnectButton } from '~/app/(accounts)/_components';
import { AccountAvatarBlockDektop } from '~/app/(accounts)/_components/desktop/AccountAvatarBlockDesktop';
import { IdentityContext, PeerAccountContext, StyledButton } from '~/app/_components';
import { ConnectionState } from '~/generated/graphql';
import { meRoute } from '~/utils';
import { ProfileDesktopContactInfo } from './ProfileDesktopContactInfo';
import { ProfilePhotoAlbum } from './ProfilePhotoAlbum';
import { useContext } from 'react';
function ActionButton() {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    account
  } = useContext(PeerAccountContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  if (isCurrentUserProfile) return <StyledButton type="black" text="Update profile" href={meRoute().editProfile} width="100%" />;
  if (account.connectionStatus === ConnectionState.Connected) return <ProfileDesktopContactInfo />;
  return <ConnectButton data-sentry-element="ConnectButton" data-sentry-component="ActionButton" data-sentry-source-file="ProfileDesktopNotWorkin.tsx" />;
}
function ProfileWithFolio() {
  const {
    account
  } = useContext(PeerAccountContext);
  if (!account) return null;

  // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  // const leftColumnRef = useRef<HTMLDivElement>(null)
  // // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  // const [leftColumnScrollable, setLeftColumnScrollable] = useState(false)

  // // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  // useEffect(() => {
  //   const leftColumn = leftColumnRef.current
  //   if (leftColumn) {
  //     // Check if the left column has overflowing content
  //     setLeftColumnScrollable(leftColumn.scrollHeight > leftColumn.clientHeight)
  //   }
  // }, [])

  return <Flex px="100px" style={{
    minHeight: 'calc(100vh - 70px)'
  }} data-sentry-element="Flex" data-sentry-component="ProfileWithFolio" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
      {/* Left Column */}
      <div style={{
      width: '30%'
    }}>
        <div
      //  ref={leftColumnRef}

      //mt="100px"
      //gap="lg"
      style={{
        position: 'sticky',
        bottom: '0'
      }}>
          <Flex direction="column" align="center" justify="center" gap="30px" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
            <AccountAvatarBlockDektop data-sentry-element="AccountAvatarBlockDektop" data-sentry-source-file="ProfileDesktopNotWorkin.tsx" />
            <ActionButton data-sentry-element="ActionButton" data-sentry-source-file="ProfileDesktopNotWorkin.tsx" />

            {/* Scrollable Text Area */}
            <Text size="sm" data-testid="about-desktop-section" data-sentry-element="Text" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
              {account.profile?.about}
            </Text>
          </Flex>
        </div>
      </div>

      {/* Right Column */}
      <Flex w="70%" style={{
      paddingBottom: '10vh'
    }} data-sentry-element="Flex" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
        <Flex align="center" justify="center" h="100%" style={{
        flexGrow: 1
      }} data-sentry-element="Flex" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
          {account.folioItems && account.folioItems?.length >= 1 ? <div style={{
          marginTop: '120px'
        }}>
              <ProfilePhotoAlbum />
            </div> : null}
        </Flex>
      </Flex>
    </Flex>;
}

// function ProfileWithFolio() {
//   //const { currentAccount: account } = useContext(IdentityContext)
//   const { account } = useContext(PeerAccountContext)
//   if (!account) return null
//   return (
//     <Grid grow={true} px="100px" mah="calc(100vh - 70px)">
//       <Grid.Col
//         span={2}
//         // offset={1}
//         h={'calc(100vh - 100px)'}
//         mah={'calc(100vh - 100px)'}
//       >
//         <Flex
//           direction="column"
//           align="center"
//           // justify="center"
//           mt="100px"
//           gap="lg"
//           h="100%"
//           // style={{ position: 'fixed' }}
//         >
//           <Flex
//             direction="column"
//             align="center"
//             justify="center"
//             gap="30px"
//             // h="50%"
//           >
//             <AccountAvatarBlockDektop />

//             <ActionButton />

//             <Text size="sm" data-testid="about-desktop-section">
//               {account.profile?.about}
//             </Text>
//           </Flex>
//         </Flex>
//       </Grid.Col>

//       <Grid.Col span={8} offset={0}>
//         <Flex align="center" justify="center" h="100%">
//           {account.folioItems && account.folioItems?.length >= 1 ? (

//             <div
//               style={{
//                 minHeight: '200px',
//                 marginBottom: '10vh',
//                 marginTop: '120px',
//               }}
//             >
//               <ProfilePhotoAlbum />
//             </div>
//           ) : null}
//         </Flex>
//       </Grid.Col>
//     </Grid>
//   )
// }

function EmptyProfileForOwner() {
  const {
    account
  } = useContext(PeerAccountContext);
  return <Flex align="center" justify="center" mt="100px" gap="lg" h="100%" w="100%" data-sentry-element="Flex" data-sentry-component="EmptyProfileForOwner" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
      <Flex w="350px" direction="column" gap="xl" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
        <ScrollArea type="hover" offsetScrollbars={true} scrollbarSize="sm" mah="70%" data-sentry-element="ScrollArea" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
          <Flex direction="column" align="center" justify="center" gap="lg"
        // h="50%"
        data-sentry-element="Flex" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
            <AccountAvatarBlockDektop centerTitle={true} data-sentry-element="AccountAvatarBlockDektop" data-sentry-source-file="ProfileDesktopNotWorkin.tsx" />

            <Text c="dimmed" style={{
            fontSize: '15px'
          }} data-sentry-element="Text" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
              {account.profile?.about}
            </Text>
          </Flex>
        </ScrollArea>
        <StyledButton type="black" text="Update profile"
      // size={mobile ? 'medium' : 'small'}
      href={meRoute().editProfile} width="350px" data-sentry-element="StyledButton" data-sentry-source-file="ProfileDesktopNotWorkin.tsx" />
        <Card bg="#48FFB9"
      // w={mobile ? '80%' : undefined}
      p="md" radius="lg" mb="xl" data-sentry-element="Card" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
          <Flex direction="column" gap="md" justify="center" data-sentry-element="Flex" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
            <Text ta="center" data-sentry-element="Text" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
              Your profile is currently not visible to other users because it's missing folio images.
            </Text>
            <Text ta="center" data-sentry-element="Text" data-sentry-source-file="ProfileDesktopNotWorkin.tsx">
              Click <span style={{
              fontWeight: 'bold'
            }}>Update Profile</span> to add some.
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>;
}
export function ProfileDesktopNotWorkin() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;

  // NOTE: showing profile even if no folios, but is not visible in community, search, or contacts
  const hasFolioItems = account.folioItems && account.folioItems.length > 0;
  if (isCurrentUserProfile && !hasFolioItems) return <EmptyProfileForOwner />;
  return <ProfileWithFolio data-sentry-element="ProfileWithFolio" data-sentry-component="ProfileDesktopNotWorkin" data-sentry-source-file="ProfileDesktopNotWorkin.tsx" />;
}