/* eslint-disable @typescript-eslint/no-empty-function */
'use client';

import type React from 'react';
import { createContext, useMemo } from 'react';
import { type RelayConnectionActor, mkRelayConnectionMachineCtx, useAppMachine } from '~/machines';
export type RelayPaginationContextType = {
  actor: RelayConnectionActor;
  id: string;
};
export const RelayPaginationContext = createContext<RelayPaginationContextType>({} as RelayPaginationContextType);
export function RelayPaginationProvider({
  children,
  id
}: React.PropsWithChildren<{
  id: string;
}>) {
  const ctx = useMemo(() => mkRelayConnectionMachineCtx({
    id
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const actorRef = useAppMachine(ctx);
  const values = useMemo(() => ({
    actor: actorRef as RelayConnectionActor,
    id
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [id]);
  return <RelayPaginationContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="RelayPaginationProvider" data-sentry-source-file="RelayPaginationContext.tsx">{children}</RelayPaginationContext.Provider>;
}