'use client';

import type React from 'react';
import { createContext } from 'react';
import { PeerAccountProvider } from '~/app/_components';
import type { FullProjectAsViewerFragment } from '~/generated/graphql';
export type ProjectContextType = {
  project?: FullProjectAsViewerFragment;
};
export const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);
export function ProjectProvider({
  project,
  children
}: React.PropsWithChildren & {
  project: FullProjectAsViewerFragment | null;
}) {
  if (!project) return null;
  return <PeerAccountProvider account={project.account} data-sentry-element="PeerAccountProvider" data-sentry-component="ProjectProvider" data-sentry-source-file="ProjectContext.tsx">
      <ProjectContext.Provider value={{
      project
    }} data-sentry-element="unknown" data-sentry-source-file="ProjectContext.tsx">{children}</ProjectContext.Provider>
    </PeerAccountProvider>;
}