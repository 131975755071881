'use client';

import { BriefcaseIcon, Cog6ToothIcon, FilmIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { Divider, Flex, Text, Title } from '@mantine/core';
import { useMutation, useQuery } from '@urql/next';
import { useSelector } from '@xstate/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import type React from 'react';
import { Suspense, startTransition, useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { ConnectButton } from '~/app/(accounts)/_components';
import { MarkNotificationAsRead } from '~/app/_graphql';
import type { FullNotificationFragment } from '~/generated/graphql';
import { PeerAccountProvider } from '../context';
import { AccountNotificationsContext } from './_components';
import { GetPaginatedNotifications } from './_lib';
import { inViewTriggerDiv, messageWrapper } from './styles.css';
import { SafeAvatarImage } from '~/app/_components';
export function NotificationsList({
  edges
}: {
  edges: {
    node: FullNotificationFragment;
    cursor: string;
  }[];
}) {
  dayjs.extend(relativeTime);
  const [, update] = useMutation(MarkNotificationAsRead);
  async function handleNotificationOnClick() {
    const _t = await update({});
  }
  function ActionButtons({
    notification
  }: {
    notification: FullNotificationFragment;
  }) {
    if (notification.friendship?.requester) {
      return <PeerAccountProvider account={notification.friendship.requester}>
          <ConnectButton defaultHide={true} />
        </PeerAccountProvider>;
    }
    return <> </>;
  }
  function MessageComponent(message: string) {
    return <div dangerouslySetInnerHTML={{
      __html: message
    }} data-sentry-component="MessageComponent" data-sentry-source-file="NotificationsList.tsx" />;
  }
  function TitleComponent(message: string) {
    return <div dangerouslySetInnerHTML={{
      __html: message
    }} data-sentry-component="TitleComponent" data-sentry-source-file="NotificationsList.tsx" />;
  }
  function renderNotificationIcon(iconUrl: string) {
    const iconMap: {
      [key: string]: JSX.Element;
    } = {
      settingsIcon: <Cog6ToothIcon height={30} color="gray" />,
      filmIcon: <FilmIcon height={30} color="gray" />,
      handThumbUp: <HandThumbUpIcon height={30} color="gray" />
    };
    return iconMap[iconUrl] || <BriefcaseIcon height={30} color="gray" />;
  }
  return <div data-testid="accept-wrapper" data-sentry-component="NotificationsList" data-sentry-source-file="NotificationsList.tsx">
      {edges.map(edge => <div key={edge.cursor}>
          <Flex key={edge.cursor} p="sm" className={messageWrapper} align="flex-start" gap="md" onClick={() => {
        handleNotificationOnClick();
      }} data-testid="notification-title" style={{
        backgroundColor: edge.node.isRead ? undefined : '#f1f3f5'
      }}>
            <Flex direction="column" justify="center" h="100%">
              {edge.node.icon?.startsWith('http') ? <SafeAvatarImage radius="sm" src={edge.node.icon} /> : <div>{renderNotificationIcon(edge.node.icon ?? '')}</div>}
            </Flex>

            <Flex direction="column" justify="center" h="100%" w="100%">
              <Text data-testid="notification-avatar" size="lg">
                {TitleComponent(edge.node.title ?? '')}
              </Text>
              <Text c="dimmed" size="md">
                {MessageComponent(edge.node.content ?? '')}
              </Text>
              <Title order={6} mt="5px">
                {dayjs(edge.node.createdAt).fromNow()}
              </Title>
              {edge.node.friendship?.requester ?
          // <Flex gap="md" mt="xs">
          <ActionButtons notification={edge.node} /> :
          // </Flex>
          null}
            </Flex>
          </Flex>
          <Divider color={edge.node.isRead ? '#f1f1f1' : 'white'} />
          <div key={edge.node.id} />
        </div>)}
    </div>;
}
function NotificationsResultInner({
  page,
  skipRendering
}: {
  page: number;
  read?: boolean;
  skipRendering?: boolean;
}) {
  const {
    actor,
    query
  } = useContext(AccountNotificationsContext);
  const variables = useSelector(actor, s => s.context.pageArgsList[page]);

  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  const [{
    data,
    fetching,
    error
  }] = useQuery({
    query: GetPaginatedNotifications,
    variables: {
      ...variables,
      readState: query // TODO refactor to '...query', need to update schema etc
    },
    pause: !variables
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (data?.currentAccount?.__typename === 'Error') {
      // why?
    } else {
      const pageInfo = data?.currentAccount?.notifications?.pageInfo;
      const totalCount = data?.currentAccount?.notifications?.totalCount;
      // console.log('pageinfo', pageInfo)

      startTransition(() => {
        if (pageInfo) {
          console.log('sending update page info', {
            type: 'UPDATE_PAGE_INFO',
            pageNumber: page,
            pageInfo,
            totalCount
          });
          actor.send({
            type: 'UPDATE_PAGE_INFO',
            pageNumber: page,
            pageInfo,
            totalCount
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (skipRendering) return null;
  if (data?.currentAccount?.__typename === 'Error') {
    return null;
  }
  const notificationsEdges = data?.currentAccount?.notifications?.edges || [];
  const Inner = NotificationsList;
  return <Inner edges={notificationsEdges} data-sentry-element="Inner" data-sentry-component="NotificationsResultInner" data-sentry-source-file="NotificationsList.tsx" />;
}
function ScrollFetcher({
  children
}: React.PropsWithChildren) {
  const {
    actor
  } = useContext(AccountNotificationsContext);
  const actorState = useSelector(actor, s => s);
  function notifyMachine(inView: boolean) {
    console.log('notifyMachine IN_VIEW ', inView);
    if (inView) startTransition(() => actor.send({
      type: 'NEXT_PAGE'
    }));
  }
  const {
    ref
  } = useInView({
    rootMargin: '1000px 0px',
    triggerOnce: true,
    initialInView: false,
    onChange: notifyMachine
  });
  const inViewKey = `notifications-page-${actorState.context.lastLoadedPage}`;
  return <>
      {children}
      <div className={inViewTriggerDiv} key={inViewKey} ref={ref} />
    </>;
}
export function NotificationsListComponent({
  skipRendering = false
} = {}) {
  const {
    actor
  } = useContext(AccountNotificationsContext);
  // const pageArgsList = useSelector(actor, (state) => state.context.pageArgsList)
  const pages = useSelector(actor, state => Object.keys(state.context.pageArgsList).map(Number));

  // WARNING
  // the suspense around SearchResultPage is required
  // otherwise the higher level suspense will be triggered
  // and the scroll will jump
  return <ScrollFetcher data-sentry-element="ScrollFetcher" data-sentry-component="NotificationsListComponent" data-sentry-source-file="NotificationsList.tsx">
      <>
        {pages.map(page => <Suspense key={page}>
            <NotificationsResultInner page={page} key={page} skipRendering={skipRendering} />
          </Suspense>)}
      </>
    </ScrollFetcher>;
}