import { graphql } from '~/generated/gql'

export const MarkNotificationAsRead = graphql(/* GraphQL */ `
  mutation MarkNotificationAsRead {
    markAsRead {
      __typename
      ... on Account {
        ...TrimmedAccount
        ...FullUnreadNotifications
      }
      ... on Error {
        message
      }
    }
  }
`)
