import 'yet-another-react-lightbox/styles.css';
import { HeartIcon as HeartOutline } from '@heroicons/react/24/outline';
import { HeartIcon as HeartSolid } from '@heroicons/react/24/solid';
import { Badge, Flex, Modal, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useContext } from 'react';
import { CaptionModalInner } from '~/app/(accounts)/accounts/[id]/[[...pageParams]]/_components/desktop/CaptionModalInner';
import { captionBadge } from '~/app/(accounts)/accounts/styles.css';
import { AppLayoutContext, IdentityContext, PeerAccountContext, useAppAuth } from '~/app/_components';
import type { VideoItem } from '~/generated/graphql';
import { useVideoLikeHandler } from './useLikeVideoHandler';
interface SortedFolioImageProps {
  vi: VideoItem;
}
export function VideoFolioItem({
  vi
}: SortedFolioImageProps) {
  const {
    isSignedIn
  } = useAppAuth();
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    account
  } = useContext(PeerAccountContext);
  const isOwner = currentAccount?.id === account.id;
  const [selectedVideo, setSelectedVideo] = React.useState<VideoItem | undefined>(undefined);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    solidHeart,
    handleLikeVideoOnClick,
    handleUnLikeVideoOnClick,
    getPlatform
  } = useVideoLikeHandler({
    account: account,
    hasLiked: vi.hasLiked
  });
  const [opened, {
    open: captionOpen,
    close: captionClose
  }] = useDisclosure(false);
  function handleBadgeOnClick(item: VideoItem) {
    setSelectedVideo(item);
    captionOpen();
  }
  return <>
      <div key={vi.id} style={{
      marginBottom: '50px',
      marginLeft: mobile ? '10px' : undefined,
      marginRight: mobile ? '10px' : undefined
    }}>
        <div key={vi.id} style={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        paddingTop: '56.25%'
      }}>
          <iframe title="Video player" src={getPlatform(vi.kind, vi.url)}
        // width="560"
        height="inherit" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        }}
        // className={responsiveImage}
        />
        </div>
        <Flex gap="lg" w="100%" justify="center" mt="5px" data-sentry-element="Flex" data-sentry-source-file="VideoFolioItem.tsx">
          {isOwner ? <Flex w="100%" justify="center" align="center" gap="xl" direction="row"
        // mt="-lg"
        >
              <Flex>
                <HeartSolid height={20} color="black" />
                <Text c="darkgray" size="sm" ml="5px">
                  {vi.totalCountLikes}
                </Text>
              </Flex>
              <Text size="xs" c="dimmed">
                {vi.caption ? vi.caption : undefined}
              </Text>
              <Badge
          // leftSection={vi.caption ? <PencilIcon height={12} /> : <PlusIcon height={15} />}
          color="black" ta="center" style={{
            cursor: 'pointer'
          }} onClick={() => handleBadgeOnClick(vi)} classNames={{
            root: captionBadge
          }}>
                {vi.caption ? 'EDIT CAPTION' : 'ADD CAPTION'}
              </Badge>
            </Flex> : isSignedIn ? solidHeart ? <>
                <Tooltip label="Remove like">
                  <HeartSolid color="black" height={25} style={{
              cursor: 'pointer'
            }} onClick={() => handleUnLikeVideoOnClick(vi.id)} />
                </Tooltip>
                <Text size="xs" c="dimmed" ta="center" mt="4px" pt="0px">
                  {vi.caption ? vi.caption : undefined}
                </Text>
              </> : <>
                <Tooltip label="Like video">
                  <HeartOutline color="black" height={25} style={{
              cursor: 'pointer'
            }} onClick={() => handleLikeVideoOnClick(vi.id)} />
                </Tooltip>
                <Text size="xs" c="dimmed" ta="center" mt="4px" pt="0px">
                  {vi.caption ? vi.caption : undefined}
                </Text>
              </> : <Text size="xs" c="dimmed" ta="center" mt="4px" pt="0px">
              {vi.caption ? vi.caption : undefined}
            </Text>}
        </Flex>
      </div>
      <Modal opened={opened} onClose={() => {
      setSelectedVideo(undefined);
      captionClose();
    }} withOverlay={false} withCloseButton={false}
    // closeOnClickOutside
    // overlayProps={{ backgroundOpacity: 0.8, blur: 0.9, color: 'black' }}
    data-sentry-element="Modal" data-sentry-source-file="VideoFolioItem.tsx">
        <CaptionModalInner selectedVideo={selectedVideo} setSelected={setSelectedVideo} closeCaption={captionClose} data-sentry-element="CaptionModalInner" data-sentry-source-file="VideoFolioItem.tsx" />
      </Modal>
    </>;
}