'use client';

import { useLocalStorage } from '@mantine/hooks';
import type React from 'react';
import { createContext, useContext } from 'react';
import { DEPLOYMENT, ENABLE_GRAPHQL_DELAY_FLAG, ENABLE_TRACING_FLAG, ENABLE_XSTATE_INSPECTOR_FLAG, appTrace, publicRuntimeConfig } from '~/utils';
export const AppContextFlags = ['disableOnboarding', 'showNextVersion', 'showDevelopersVersion', ENABLE_TRACING_FLAG, ENABLE_GRAPHQL_DELAY_FLAG, ENABLE_XSTATE_INSPECTOR_FLAG] as const;
export type AppContextFlagName = (typeof AppContextFlags)[number];
type FlagsType = Partial<Record<AppContextFlagName, boolean>>;
type FlagSettersType = Partial<Record<AppContextFlagName, (a: boolean | ((b: boolean) => boolean)) => void>>;
export type LocalConfigContextType = {
  flags: FlagsType;
  flagSetters: FlagSettersType;
};
export const LocalConfigContext = createContext<LocalConfigContextType>({
  flags: {},
  flagSetters: {}
});
const localConfigPresets: Record<DEPLOYMENT | 'default', FlagsType> = {
  local: {},
  staging: {},
  stable: {
    enableGraphqlDelay: false,
    showDevelopersVersion: false,
    enableTracing: false
  },
  default: {
    disableOnboarding: false,
    enableXstateInspector: false,
    showNextVersion: false,
    enableTracing: true,
    enableGraphqlDelay: true,
    showDevelopersVersion: true
  }
};
export function LocalConfigProvider({
  children
}: React.PropsWithChildren) {
  const deployment = publicRuntimeConfig.deployment;
  const [flags, flagSetters] = AppContextFlags.reduce(([flagsAcc, settersAcc], key) => {
    // TODO: broken, fix default value - it's ignoring falsy values
    const defaultValue = localConfigPresets[deployment]?.[key] ?? localConfigPresets.default[key];
    // biome-ignore lint/correctness/useHookAtTopLevel: static array
    const [value, valueSetter] = useLocalStorage({
      key,
      defaultValue
    });
    return [{
      ...flagsAcc,
      [key]: value
    }, {
      ...settersAcc,
      [key]: (a: boolean | ((b: boolean) => boolean)) => {
        appTrace('flagSetter', key);
        valueSetter(a);
      }
    }];
  }, [{}, {}] as [FlagsType, FlagSettersType]);
  appTrace('LocalConfigProvider', flags);
  return <LocalConfigContext.Provider value={{
    flags,
    flagSetters
  }} data-sentry-element="unknown" data-sentry-component="LocalConfigProvider" data-sentry-source-file="LocalConfigContext.tsx">{children}</LocalConfigContext.Provider>;
}
export function OnlyUnreleasedVersion({
  children
}: React.PropsWithChildren) {
  const {
    flags
  } = useContext(LocalConfigContext);
  return flags.showNextVersion ? <>{children}</> : null;
}
export function OnlyReleasedVersion({
  children
}: React.PropsWithChildren) {
  const {
    flags
  } = useContext(LocalConfigContext);
  return flags.showNextVersion ? null : <>{children}</>;
}
export function OnlyForDevelopers({
  children
}: React.PropsWithChildren) {
  const {
    flags
  } = useContext(LocalConfigContext);
  return flags.showDevelopersVersion ? <>{children}</> : null;
}
export function LocalConfigProviderStorybook({
  children
}: React.PropsWithChildren) {
  const values = {
    flags: {},
    flagSetters: {}
  };
  return <LocalConfigContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="LocalConfigProviderStorybook" data-sentry-source-file="LocalConfigContext.tsx">{children}</LocalConfigContext.Provider>;
}