'use client';

// import { useContext } from 'react'

// import { AppLayoutContext } from '../../context'
import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import { renderTitleText } from '~/app/(me)/onboarding/_lib';
import { AccountType, TrimmedCurrentAccountFragment } from '~/generated/graphql';
import { IdentityContext } from '../../context';
import { Title } from '@mantine/core';
function getFirstName(name: string | null | undefined) {
  return name?.split(' ')[0] ?? '';
}
function verifyTitleText(pathname: string, account?: TrimmedCurrentAccountFragment) {
  if (pathname !== '/onboarding/profile') {
    return renderTitleText(pathname, account);
  }
  if (!account) return 'Hi, tell us about yourself';
  if (account.type === AccountType.Brand) return `Hi ${account.profile?.name}, tell us about your brand`;
  if (account.type === AccountType.TalentAgency) return `Hi ${account.profile?.name}, tell us about your talent agency`;
  return `Hi ${getFirstName(account.profile?.name)}, tell us about yourself`;
}
export function OnboardingTitle() {
  const pathname = usePathname();
  const {
    currentAccount
  } = useContext(IdentityContext);
  return <Title order={2} fw={600} mx="xl" data-sentry-element="Title" data-sentry-component="OnboardingTitle" data-sentry-source-file="OnboardingTitle.tsx">
      {verifyTitleText(pathname, currentAccount)}
    </Title>;
}