'use client';

import { BellIcon } from '@heroicons/react/24/solid';
import { Badge } from '@mantine/core';
import { useSelector } from '@xstate/react';
import { useContext } from 'react';
import { WaitForStableAuthState } from '../../auth';
import { AppLayoutContext, IdentityContext } from '../../context';
import { AccountNotificationsContext } from '../../notifications/_components';
import { headerElement } from './styles.css';
type NotificationNavPropsType = {
  openedNotifications: boolean;
  closeNotifications: () => void;
  openNotifications: () => void;
};
export function NotificationNav({
  openedNotifications,
  closeNotifications,
  openNotifications
}: NotificationNavPropsType) {
  const {
    actor
  } = useContext(AccountNotificationsContext);
  const totalCount = useSelector(actor, s => s.context.totalCount);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isNotificationBadgeVisible = totalCount && totalCount >= 1;
  return <WaitForStableAuthState context="notification-nav" data-sentry-element="WaitForStableAuthState" data-sentry-component="NotificationNav" data-sentry-source-file="NotificationNav.tsx">
      {currentAccount ? <>
          <BellIcon width={mobile ? 40 : 30} height={mobile ? 40 : 30} onClick={() => openedNotifications ? closeNotifications() : openNotifications()} className={headerElement} data-testid="notifications-button" />
          <div style={{
        position: 'relative'
      }}>
            <Badge size="md" circle style={{
          position: 'absolute',
          left: '15px',
          bottom: '19px',
          color: 'black',
          visibility: isNotificationBadgeVisible ? 'visible' : 'hidden'
        }} onClick={() => openedNotifications ? closeNotifications() : openNotifications()} className={headerElement} data-testid="notifications-badge">
              {totalCount}
            </Badge>
          </div>
        </> : null}
    </WaitForStableAuthState>;
}