import Autocomplete from 'react-google-autocomplete';

// eslint-disable-next-line react/prop-types, @typescript-eslint/no-unused-vars
export function LocationSelector() {
  //  const { location, setLocation } = useContext(AccountsFilterContext)

  // NOT CURRENTLY USED
  return <Autocomplete
  // FIXME hide
  apiKey="AIzaSyBkXCw8fd_BRfOViPsgVTsOa9QIpDOCvHo" style={{
    width: '100%',
    backgroundColor: '#f1f3f5',
    border: 'none',
    height: '2.25rem'
  }} onPlaceSelected={place => {
    console.log(place.formatted_address);
  }}
  // selectProps={{ ...field, isClearable: true }}
  options={{
    types: ['(cities)']
    // componentRestrictions: {},
  }} defaultValue="" data-sentry-element="Autocomplete" data-sentry-component="LocationSelector" data-sentry-source-file="LocationSelector.tsx" />;
}