'use client';

import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { PropsWithChildren, createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
export type AppLayoutContextType = {
  mobile: boolean;
  hiddenHeader: boolean;
  fixed: boolean;
  setHiddenHeader: (isHidden: boolean, debugMessage: string) => void;
  setFixed: (isFixed: boolean) => void;
};
export const AppLayoutContext = createContext<AppLayoutContextType>({
  mobile: false,
  fixed: true,
  setFixed: () => {
    throw new Error('setFixed must be overridden');
  },
  hiddenHeader: false,
  setHiddenHeader: () => {
    throw new Error('setHiddenHeader must be overridden');
  }
});
export function AppLayoutProvider({
  isMobile,
  isFixed,
  children
}: PropsWithChildren<{
  isMobile: boolean;
  isFixed: boolean;
}>) {
  const theme = useMantineTheme();
  const isMobileFromQuery = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [mobile, setMobile] = useState(isMobile);
  useEffect(() => {
    const newValue = !!isMobileFromQuery;
    if (mobile !== newValue) setMobile(newValue);
  }, [isMobileFromQuery]);
  const [hiddenHeader, setHiddenHeader] = useState<boolean>(false);
  const [fixed, setFixed] = useState<boolean>(isFixed);
  const setHiddenHeaderDebug = (isHidden: boolean, _debugMessage: string) => {
    setHiddenHeader(isHidden);
  };
  const values = useMemo(() => ({
    mobile,
    hiddenHeader,
    setHiddenHeader: setHiddenHeaderDebug,
    fixed,
    setFixed
  }), [mobile, hiddenHeader, fixed]);
  return <AppLayoutContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="AppLayoutProvider" data-sentry-source-file="AppLayoutContext.tsx">{children}</AppLayoutContext.Provider>;
}
export function useHiddenHeader() {
  if (typeof document !== 'undefined') {
    document.body.style.setProperty('--mantine-header-height', '0px');
  }
  const {
    setHiddenHeader
  } = useContext(AppLayoutContext);
  useLayoutEffect(() => {
    setHiddenHeader(true, 'use-hidden-header-mount');
    return () => setHiddenHeader(false, 'use-hidden-header-unmount');
  });
}
export function useNonFixedLayout() {
  const {
    setFixed
  } = useContext(AppLayoutContext);
  useLayoutEffect(() => {
    setFixed(false);
    return () => setFixed(true);
  });
}