import { graphql } from '~/generated/gql'

export const LikeImage = graphql(/* GraphQL */ `
  mutation LikeImage(
    $fileId: String!
    $type: FileTypeLike!
    $action: likeTargetAction!
  ) {
    # Write your query or mutation here
    likeImage(id: $fileId, type: $type, action: $action) {
      ... on FolioItem {
        __typename
        id
        hasLiked
        totalCountLikes
      }
    }
  }
`)

export const LikeVideo = graphql(/* GraphQL */ `
  mutation LikeVideo($fileId: String!, $action: likeTargetAction!) {
    # Write your query or mutation here
    likeVideo(id: $fileId, action: $action) {
      ... on VideoItem {
        __typename
        id
        hasLiked
        totalCountLikes
      }
    }
  }
`)
