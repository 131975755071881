import { Suspense, useContext } from 'react';
import { type Resource, appTrace, useSuspensefulGetter } from '~/utils';
import { NoSsr } from '../context';
import { AuthActorContext } from './AuthProvider';
function WaitForAuthInner({
  children,
  resource
}: {
  children: React.ReactNode;
  resource: Resource<any>;
}) {
  appTrace(`WaitForOnboarded: ${JSON.stringify(resource.read())}`);
  return <>{children}</>;
}
export function WaitForOnboarded({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const resource = useSuspensefulGetter(authActor, s => s.matches('registration.complete'), s => s.value);
  return <NoSsr data-sentry-element="NoSsr" data-sentry-component="WaitForOnboarded" data-sentry-source-file="WaitForOnboarded.tsx">
      <Suspense data-sentry-element="Suspense" data-sentry-source-file="WaitForOnboarded.tsx">
        <WaitForAuthInner resource={resource} data-sentry-element="WaitForAuthInner" data-sentry-source-file="WaitForOnboarded.tsx">{children}</WaitForAuthInner>
      </Suspense>
    </NoSsr>;
}