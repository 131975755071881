import Image from 'next/image';
import { ComponentProps, FC } from 'react';
import { globalTheme } from '~/styles/mantineThemeOverride';
type NextImageProps = ComponentProps<typeof Image>;
type BreakpointSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
// ugly but it works
type SafeImageParams = Omit<NextImageProps, 'src' | 'sizes' | 'width'> & {
  src?: NextImageProps['src'] | null;
} & {
  sizes?: NextImageProps['sizes'];
  width?: NextImageProps['width'];
  breakpoints?: Record<BreakpointSize, number>;
} & ({
  sizes: NextImageProps['sizes'];
} | {
  width: NextImageProps['width'];
} | {
  breakpoints: Record<BreakpointSize, number>;
});
const breakpointOrder: BreakpointSize[] = ['xs', 'sm', 'md', 'lg', 'xl'];

// {
//   xs: '36em',
//   sm: '48em',
//   md: '62em',
//   lg: '75em',
//   xl: '88em',
// },

const breakpointValues = globalTheme.breakpoints!;
const imageSizeForBreakpoint = (width: number, breakpoint: BreakpointSize) => {
  if (breakpoint === 'xs') return `${width}vw`;

  //return `${width * 0.01 * Number.parseInt(breakpointValues[breakpoint])}em`
  return `${width}vw`;
};
export const SafeImage: FC<SafeImageParams> = ({
  src,
  sizes,
  width,
  breakpoints,
  ...restProps
}) => {
  if (!src) return null;
  let properSizes: string | undefined;
  if (typeof width === 'string') {
    properSizes = width.endsWith('px') ? width : `${Number.parseInt(width)}px`;
  } else if (typeof width === 'number') {
    properSizes = `${width}px`;
  }
  if (breakpoints) {
    properSizes = breakpointOrder.filter(breakpoint => breakpoints[breakpoint] !== undefined).map(breakpoint => `(min-width: ${breakpointValues[breakpoint]}) ${imageSizeForBreakpoint(breakpoints[breakpoint], breakpoint)}`).join(', ');
  }
  // console.log('SafeImage', { src, sizes, width, properSizes })

  return <Image src={src} width={width} sizes={sizes ?? properSizes} {...restProps} data-sentry-element="Image" data-sentry-component="SafeImage" data-sentry-source-file="SafeImage.tsx" />;
};