'use client';

import { graphql } from '~/generated/gql';
export const GetPaginatedAccounts = graphql( /* GraphQL */`
  query GetPaginatedAccounts(
    $first: Int!
    $after: String
    $query: AccountsSearchQuery
  ) {
    __typename
    accounts(first: $first, after: $after, query: $query) {
      __typename
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        __typename
        cursor
        node {
          ...TrimmedAccount
        }
      }
    }
  }
`);