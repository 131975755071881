import { fromPromise } from 'xstate'

import { authenticate } from './actors'
import { createAuthMachine } from './machine'
export const authMachineCtx = createAuthMachine({})
export const authMachine = authMachineCtx.machine.provide({
  actors: {
    authenticate: fromPromise(({ input }) => authenticate(input)),
  },
})
