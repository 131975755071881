'use client';

import { useSelector } from '@xstate/react';
import { useContext } from 'react';
import { AccountNotificationsContext, AccountNotificationsProvider } from '../notifications/_components';
import { NotificationsListComponent } from '~/app/_components';
export function NotificationsTabs() {
  const {
    actor
  } = useContext(AccountNotificationsContext);
  const _totalCount = useSelector(actor, s => s.context.totalCount);
  return <>
      <NotificationsListComponent data-sentry-element="NotificationsListComponent" data-sentry-source-file="NotificationsTabs.tsx" />

      <AccountNotificationsProvider unread={false} data-sentry-element="AccountNotificationsProvider" data-sentry-source-file="NotificationsTabs.tsx">
        <NotificationsListComponent data-sentry-element="NotificationsListComponent" data-sentry-source-file="NotificationsTabs.tsx" />
      </AccountNotificationsProvider>
    </>;
}