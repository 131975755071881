import { graphql } from '~/generated'

export const AcceptOrCreateFriendship = graphql(/* GraphQL */ `
  mutation acceptOrCreateFriendship($recipientId: String!, $message: String) {
    acceptOrCreateFriendship(recipientId: $recipientId, message: $message) {
      __typename
      ... on Account {
        id
        requestedFriendships {
          edges {
            cursor
            node {
              addressee {
                id
                connectionStatus
              }
              requester {
                id
              }
              state
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
        messages {
          authorId
          recipientId
          content
        }
        ...FullUnreadNotifications
      }
      ... on Error {
        message
      }
    }
  }
`)
