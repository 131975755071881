'use client';

import { useContext } from 'react';
import { DesktopProfileCard, MobileProfileCard } from '~/app/(accounts)/_components';
import { AppLayoutContext, PeerAccountProvider } from '~/app/_components';
import { TrimmedAccountFragment } from '~/generated/graphql';
export function AccountProfileCard({
  account
}: {
  account: TrimmedAccountFragment;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const ProfileCard = mobile ? MobileProfileCard : DesktopProfileCard;
  return <div data-testid="card-wrapper"
  // style={{ backgroundColor: mobile ? '#f6f6f6' : undefined }}
  data-sentry-component="AccountProfileCard" data-sentry-source-file="AccountProfileCard.tsx">
      <div data-testid={account.id} role="listitem">
        <PeerAccountProvider account={account} data-sentry-element="PeerAccountProvider" data-sentry-source-file="AccountProfileCard.tsx">
          <ProfileCard data-sentry-element="ProfileCard" data-sentry-source-file="AccountProfileCard.tsx" />
        </PeerAccountProvider>
      </div>
    </div>;
}