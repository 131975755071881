'use client';

import type React from 'react';
import { AppShell } from '@mantine/core';
import { useHeadroom } from '@mantine/hooks';
import { usePathname } from 'next/navigation';
import { useContext, useEffect, useState } from 'react';
import { AppLayoutContext } from '../../context';
import { Header } from '../header';
export const defaultHeaderHeight = 70;
export const defaultHeaderHeightPx = `${defaultHeaderHeight}px`;
export function AppShellTemplate({
  children
}: React.PropsWithChildren) {
  const {
    hiddenHeader,
    mobile
  } = useContext(AppLayoutContext);
  const pinned = useHeadroom({
    fixedAt: 140
  });
  const [headerHeight, setHeaderHeight] = useState(defaultHeaderHeight);
  const pathname = usePathname();
  useEffect(() => {
    const headerHeightValue = hiddenHeader ? 0 : defaultHeaderHeight;
    document.body.style.setProperty('--mantine-header-height', `${headerHeightValue}px`);
    setHeaderHeight(headerHeightValue);
  }, [hiddenHeader]);
  const calculatePaddingTop = () => {
    if (pathname.includes('/onboarding')) return '0px';
    return mobile ? '60px' : '100px';
  };
  return <AppShell padding="0px" header={{
    height: headerHeight,
    collapsed: hiddenHeader || !pinned,
    offset: false
  }} data-sentry-element="AppShell" data-sentry-component="AppShellTemplate" data-sentry-source-file="AppShellTemplate.tsx">
      <AppShell.Header maw="100vw" withBorder={false} style={{
      backgroundColor: 'white',
      opacity: '0.9'
    }} data-sentry-element="unknown" data-sentry-source-file="AppShellTemplate.tsx">
        <Header data-sentry-element="Header" data-sentry-source-file="AppShellTemplate.tsx" />
      </AppShell.Header>
      <AppShell.Main pt={calculatePaddingTop()} data-sentry-element="unknown" data-sentry-source-file="AppShellTemplate.tsx">{children}</AppShell.Main>
    </AppShell>;
}