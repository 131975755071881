import { createBrowserInspector } from '@statelyai/inspect'
import {
  type Actor,
  type ActorRefLike,
  type AnyActor,
  type AnyActorLogic,
  type AnyEventObject,
  type EventObject,
  type InspectionEvent,
  type MachineContext,
  type Observer,
  type ParameterizedObject,
  log,
} from 'xstate'

import { ENABLE_XSTATE_INSPECTOR_FLAG, fetchFlag } from '~/utils'

type InspectorType = ReturnType<typeof createBrowserInspector> | undefined

export const inspector: InspectorType = fetchFlag(ENABLE_XSTATE_INSPECTOR_FLAG) ? createBrowserInspector() : undefined

export class MachineDebugger {
  private enable: boolean
  private actor?: AnyActor
  private started = false
  private machineId = 'undefined'
  public consoleInspector?: Observer<InspectionEvent>
  public observers = new Set<Observer<InspectionEvent>>()
  public unifiedInspect: Observer<InspectionEvent> = {
    next: (event) => {
      this.observers.forEach((observer) => {
        if (observer.next) observer.next(event)
      })
    },
    error: (event) => {
      this.observers.forEach((observer) => {
        if (observer.error) observer.error(event)
      })
    },
    complete: () => {
      this.observers.forEach((observer) => {
        if (observer.complete) observer.complete()
      })
    },
  }

  constructor({ actor, id }: { actor?: AnyActor; id?: string } = {}) {
    this.enable = true
    //   this.enable = isTracingEnabled()
    this.actor = actor
    this.machineId = id ?? 'undefined'
    const isSSR = typeof window === 'undefined'
    const isJest = process.env.JEST_WORKER_ID !== undefined || process.env.VITEST
    if (isJest) this.observers.add(this.createJestInspector())
    if (!isJest && !isSSR) this.observers.add(this.createConsoleInspector())
    if (inspector) this.observers.add(inspector.inspect)
    if (actor) this.machineId = (actor.getSnapshot() as any).machine.id
  }

  private prefix() {
    return `[xstate ${this.machineId} ${this.actor?.id ?? '-'}]`
  }

  private log(...messages: any[]) {
    const formattedMessages = messages.map((message) =>
      typeof message === 'string' ? message : JSON.stringify(message)
    )

    console.log(this.prefix(), ...formattedMessages)
  }

  consoleError(...messages: any[]) {
    const formattedMessages = messages.map((message) =>
      typeof message === 'string' ? message : JSON.stringify(message)
    )

    console.error(this.prefix(), ...formattedMessages)
  }

  // Helper function to format leaf states
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatLeafStates(event: any): string {
    return event.value
    // const states: string[] = event.value
    // return states
    //   .filter((s) => !states.find((ss) => ss.startsWith(s) && ss !== s))
    //   .sort()
    //   .join(', ') // To make it usable in playwright
  }

  // Helper function to handle state changes - NOT correct args types
  private onStateChange(actor: Actor<AnyActorLogic>) {
    const leafStates = this.formatLeafStates(actor)
    this.log({
      value: leafStates,
      // fullState: state,
      // context: state.context.context,
      // Event: state.context.event,
    })
  }

  start(actor?: AnyActor) {
    if (!this.enable) return
    if (this.started) return
    this.started = true
    if (actor) this.actor = actor
    if (!this.actor) throw new Error('no machine set in constructor')
    if (this.actor?.getSnapshot().machine) this.machineId = this.actor?.getSnapshot().machine.id
    if (!this.machineId) throw new Error('unspecified machine id')
    this.log('starting machine debugger')
    this.actor.subscribe(
      this.onStateChange.bind(this),
      (error) => this.log(`error: ${error} `),
      () =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.log({
          event: 'machine stopped',
          snapshot: (this.actor?.getSnapshot() as any).value,
        })
    )
  }

  logAction<
    Ctx extends MachineContext,
    E1 extends EventObject,
    TP extends ParameterizedObject['params'] | undefined,
    E2 extends EventObject,
  >(msg: string) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!this.enable) return () => {}
    return log<Ctx, E1, TP, E2>((args) => `${this.prefix()} ${msg}, event: ${args.event.type}`)
  }

  consoleLog(...msgs: any[]) {
    if (!this.enable) return
    this.log(...msgs)
  }

  private createConsoleInspector(): Observer<InspectionEvent> {
    const parseRefId = (ref: ActorRefLike | undefined, _includeSystemId?: false): string | undefined => {
      if (!ref) {
        return undefined
      }

      // @ts-expect-error - Exists on the ref.src
      const id = ref.src.id

      const output = id //|| ref.id

      // if (includeSystemId) {
      //   output += `(${ref.id})`
      // }
      //
      return output
    }

    function logEvent(labelOrMsg: any, ...optionalParams: any[]): void {
      if (optionalParams && optionalParams.length > 0) {
        console.log(`%c${labelOrMsg}%c`, 'font-weight: bold;', 'color: inherit;', ...optionalParams)
      } else {
        console.log(labelOrMsg)
      }
    }

    const defaults = 'font-weight: bold; line-height: 1.5; border-radius: 8px; padding: 4px 10px;'
    const reset = 'color: inherit;'

    const Styles = {
      info: {
        label: 'background: #113264; color: #8EC8F6;', // blue 12, blue 5
        sublabel: 'background: #113264; color: #C2E5FF;', // blue 12, blue 7
      },
      success: {
        label: 'background: #203C25; color: #94CE9A;', // grass 12, grass 5
        sublabel: 'background: #203C25; color: #C9E8CA;', // grass 12, grass 7
      },
      warning: {
        label: 'background: #473B1F; color: #E4C767;', // yellow 12, yellow 5
        sublabel: 'background: #473B1F; color: #FFE770;', // yellow 12, yellow 7
      },
      error: {
        label: 'background: #5C271F; color: #F5A898;', // tomato 12, tomato 5
        sublabel: 'background: #5C271F; color: #FFCDC2;', // tomato 12, tomato 7
      },
    } as const

    type Style = keyof typeof Styles

    const logGroup = (
      {
        label,
        sublabel,
        details,
        style = 'info',
      }: { label: string; sublabel?: string; details?: string; style?: Style },
      cb: () => void
    ) => {
      const styles = Styles[style]

      const msg = [`%c${label}%c\t`]
      const params: string[] = [`${defaults} ${styles.label}`, reset]

      if (sublabel) {
        msg.push(`%c${sublabel}%c`)
        params.push(`${defaults} ${styles.sublabel}`, reset)
      }

      if (details) {
        msg.push(`%c${details}`)
        params.push(defaults)
      }

      console.groupCollapsed(msg.join(''), ...params)
      cb()
      console.groupEnd()
    }

    function determineStyleFromEvent(event: InspectionEvent | AnyEventObject): Style {
      switch (event.type) {
        case 'ROUTE.REGISTER':
          return 'success'
        case 'ROUTE.UNREGISTER':
          return 'warning'
        case 'SUBMIT':
          return 'info'
      }

      if (event.type.startsWith('xstate.done.')) {
        return 'success'
      }
      if (event.type.startsWith('xstate.error.')) {
        return 'error'
      }

      return 'info'
    }

    const consoleInspector: Observer<InspectionEvent> = {
      next: (inspectionEvent) => {
        if (inspectionEvent.type === '@xstate.actor') {
          logGroup({ label: 'ACTOR', sublabel: parseRefId(inspectionEvent.actorRef) }, () => {
            logEvent('Actor Ref', inspectionEvent.actorRef)
          })
        }

        if (inspectionEvent.type === '@xstate.event') {
          logGroup(
            {
              label: 'EVENT',
              sublabel: inspectionEvent.event.type,
              details: [parseRefId(inspectionEvent.sourceRef), parseRefId(inspectionEvent.actorRef)]
                .filter(Boolean)
                .join(' ⮕ '),
              style: determineStyleFromEvent(inspectionEvent.event),
            },
            () => {
              logEvent('Type', inspectionEvent.event.type)
              logEvent('Source', inspectionEvent.sourceRef)
              logEvent('Actor', inspectionEvent.actorRef)
              logEvent('Event', inspectionEvent.event)
            }
          )
        }

        if (inspectionEvent.type === '@xstate.snapshot') {
          logGroup(
            {
              label: 'SNAPSHOT',
              sublabel: parseRefId(inspectionEvent.actorRef),
              style: determineStyleFromEvent(inspectionEvent.event),
            },
            () => {
              logEvent('Type', inspectionEvent.event.type)
              // logEvent('Parent', parseRefId(inspectionEvent.actorRef._parent))
              logEvent('Actor', inspectionEvent.actorRef)
              logEvent('Event', inspectionEvent.event)
              logEvent('Snapshot', inspectionEvent.snapshot)
            }
          )
        }
      },
    }

    return consoleInspector
  }

  private createJestInspector(): Observer<InspectionEvent> {
    class LogGroup {
      private logs: any[] = []
      constructor(
        public label: string,
        public sublabel?: string,
        public details?: string
      ) {
        this.label = label
        this.sublabel = sublabel
        this.details = details
      }

      logEvent(labelOrMsg: any, ...optionalParams: any[]): void {
        if (optionalParams && optionalParams.length > 0) {
          this.logs.push(`${labelOrMsg}\t${optionalParams.map((p) => JSON.stringify(p)).join(' ')}`)
        } else {
          this.logs.push(labelOrMsg)
        }
      }

      printAll() {
        const msg = [`${this.label}\t`]

        if (this.sublabel) {
          msg.push(`${this.sublabel}`)
        }

        if (this.details) {
          msg.push(`${JSON.stringify(this.details)}`)
        }

        msg.push('\n')

        const allMsgs = [msg.join(' ')]

        this.logs.forEach((log) => allMsgs.push(`${log}\n`))
        console.log(allMsgs.join('  '))
      }
    }
    function parseRefId(ref: ActorRefLike | undefined, _includeSystemId?: false): string | undefined {
      if (!ref) {
        return undefined
      }

      // @ts-expect-error - Exists on the ref.src
      const id = ref.src.id

      const output = id //|| ref.id

      // if (includeSystemId) {
      //   output += `(${ref.id})`
      // }

      return output
    }

    function decoratedActor(ref: ActorRefLike) {
      const id = parseRefId(ref)
      const session = ref.sessionId
      return `${id}(${session})`
    }

    const jestInspector: Observer<InspectionEvent> = {
      next: (e) => {
        if (e.type === '@xstate.actor') {
          const logGroup = new LogGroup('ACTOR', parseRefId(e.actorRef))
          logGroup.logEvent('Actor Ref', decoratedActor(e.actorRef))
          logGroup.printAll()
        }

        if (e.type === '@xstate.event') {
          const logGroup = new LogGroup(
            'EVENT',
            e.event.type,
            [parseRefId(e.sourceRef), parseRefId(e.actorRef)].filter(Boolean).join(' ⮕ ')
          )
          logGroup.logEvent('Type', e.event.type)
          // logGroup.logEvent('Source', inspectionEvent.sourceRef)
          logGroup.logEvent('Actor', decoratedActor(e.actorRef))
          logGroup.logEvent('Event', e.event)
          logGroup.printAll()
        }

        if (e.type === '@xstate.snapshot') {
          const logGroup = new LogGroup('SNAPSHOT', parseRefId(e.actorRef), e.event.type)

          logGroup.logEvent('Type', e.event.type)
          // logGroup.logEvent('Parent', parseRefId(inspectionEvent.actorRef._parent))
          logGroup.logEvent('Actor', decoratedActor(e.actorRef))
          logGroup.logEvent('Event', e.event)
          //logGroup.logEvent('Snapshot', inspectionEvent.snapshot)
          logGroup.logEvent('Context', (e.snapshot as any).context)
          logGroup.logEvent('Value', (e.snapshot as any).value)
          logGroup.printAll()
        }
      },
    }

    return jestInspector
  }
}
