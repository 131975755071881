'use client';

import { ReactNode, useContext } from 'react';
import { appTrace } from '~/utils';
import { IdentityContext } from '../context';
import { WaitForStableAuthState } from './WaitForStableAuthState';
import { useRouter } from 'next/navigation';
import { UserRole } from '~/generated/graphql';
function EnforceAdminInner({
  children,
  redirect = true
}: {
  redirect?: boolean;
  children: ReactNode;
}) {
  const {
    currentUser
  } = useContext(IdentityContext);
  const router = useRouter();
  if (currentUser?.role !== UserRole.Admin) {
    appTrace(`EnforceAdmin: not an admin (role: ${currentUser?.role}), redirecting to /`);
    if (redirect) router.push('/');
    return null;
  }
  return children;
}
export function EnforceAdmin({
  children
}: {
  children: ReactNode;
}) {
  return <WaitForStableAuthState data-sentry-element="WaitForStableAuthState" data-sentry-component="EnforceAdmin" data-sentry-source-file="EnforceAdmin.tsx">
      <EnforceAdminInner redirect={true} data-sentry-element="EnforceAdminInner" data-sentry-source-file="EnforceAdmin.tsx">{children}</EnforceAdminInner>
    </WaitForStableAuthState>;
}
export function AdminOnly({
  children
}: {
  children: ReactNode;
}) {
  return <WaitForStableAuthState data-sentry-element="WaitForStableAuthState" data-sentry-component="AdminOnly" data-sentry-source-file="EnforceAdmin.tsx">
      <EnforceAdminInner redirect={false} data-sentry-element="EnforceAdminInner" data-sentry-source-file="EnforceAdmin.tsx">{children}</EnforceAdminInner>
    </WaitForStableAuthState>;
}