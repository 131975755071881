import { AccountType, type Account, type TrimmedCurrentAccountFragment } from '~/generated/graphql'

export function accountProfessions(account?: Partial<Pick<Account, 'professions'>> | TrimmedCurrentAccountFragment) {
  if (!account?.professions) return
  return account?.professions?.map((p) => p.name).join(', ')
}

export function accountBrand(account: Pick<Account, 'profile'>) {
  return account.profile?.brand !== null ? account.profile?.brand : ''
}

export function accountType(account: Pick<Account, 'type'>) {
  return account.type === AccountType.Brand ? 'Brand' : 'Talent Agency'
}
