'use client';

import { Button, Divider, Drawer, Flex, Text, Title, UnstyledButton } from '@mantine/core';
import { useRouter } from 'next/navigation';
import { useContext } from 'react';
import { accountRoute, meRoute } from '~/utils/routes';
import { WaitForStableAuthState, useAppAuth } from '../../auth';
import { AppLayoutContext, IdentityContext } from '../../context';
import { SafeAvatarImage } from '../../images';
import { useDisclosure } from '@mantine/hooks';
import { AccountType } from '~/generated/graphql';
import { Route } from 'next';
import { navbarItem } from './styles.css';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
export function AccountNav() {
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <WaitForStableAuthState context="nav" data-sentry-element="WaitForStableAuthState" data-sentry-component="AccountNav" data-sentry-source-file="AccountNav.tsx">
      {mobile ? <MobileAccountNav /> : <DesktopAccountNav />}
    </WaitForStableAuthState>;
}
export function SigninButton() {
  const {
    handleSignIn,
    handleSignUp
  } = useAppAuth();
  const {
    mobile
  } = useContext(AppLayoutContext);
  return <Flex gap={mobile ? 'sm' : 'xl'} align="center" data-sentry-element="Flex" data-sentry-component="SigninButton" data-sentry-source-file="AccountNav.tsx">
      {/* <Text fw={600} size="lg" style={{ cursor: 'pointer' }} onClick={handleSignIn} data-testid="signin-button">
        Sign In
       </Text> */}
      <Button px="xs" py="4px" style={{
      cursor: 'pointer',
      backgroundColor: '#48FFB9',
      color: 'black',
      border: '1px solid black',
      fontSize: '14px',
      borderRadius: '8px',
      fontWeight: '500'
    }}
    // onClick={handleSignUp}
    onClick={handleSignIn} data-testid="signin-button" data-sentry-element="Button" data-sentry-source-file="AccountNav.tsx">
        Sign In/Sign Up
      </Button>
    </Flex>;
}
function MobileAccountNav() {
  const {
    currentAccount
  } = useContext(IdentityContext);
  if (!currentAccount?.profile) return <SigninButton />;

  // We do not want to show the name on mobile - to small space
  return null;
}
function DesktopAccountNav() {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const [opened, {
    open,
    close
  }] = useDisclosure(false);
  if (!currentAccount?.profile) return <SigninButton />;
  return <>
      <Button h="100%" data-testid="accountNav-btn" style={{
      backgroundColor: 'transparent'
    }} p="xs" pr="0px" mr="0px" onClick={opened ? close : open} data-sentry-element="Button" data-sentry-source-file="AccountNav.tsx">
        <SafeAvatarImage radius="xxs" account={currentAccount} data-sentry-element="SafeAvatarImage" data-sentry-source-file="AccountNav.tsx" />
        <Text size="lg" fw={600} mx="xs" tt={'uppercase'} data-sentry-element="Text" data-sentry-source-file="AccountNav.tsx">
          {currentAccount.profile.name}
        </Text>
        {opened ? <ChevronUpIcon height={20} /> : <ChevronDownIcon height={20} />}
      </Button>

      <Drawer opened={opened} onClose={close} position="right" size="lg" withCloseButton={false} styles={{
      content: {
        marginTop: '70px' // Adds space inside the drawer content to prevent overlap with header
      },
      overlay: {
        marginTop: '70px' // Adds space inside the drawer content to prevent overlap with header
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="AccountNav.tsx">
        <DesktopNavDrawer close={close} data-sentry-element="DesktopNavDrawer" data-sentry-source-file="AccountNav.tsx" />
      </Drawer>
    </>;
}
function DesktopNavDrawer({
  close
}: {
  close: () => void;
}) {
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    handleSignOut
  } = useAppAuth();
  const router = useRouter();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const MenuItem = ({
    label,
    onClick,
    disabled = false
  }: {
    label: string;
    onClick: any;
    disabled?: boolean;
  }) => <Title tt="uppercase" order={1} onClick={!disabled ? onClick : undefined} style={{
    cursor: disabled ? 'not-allowed' : 'pointer'
  }} c={disabled ? 'dimmed' : undefined} className={navbarItem} data-sentry-element="Title" data-sentry-component="MenuItem" data-sentry-source-file="AccountNav.tsx">
      {label}
    </Title>;
  const handleClick = (path: Route) => {
    close();
    router.push(path);
  };
  if (!currentAccount) return null;
  return <Flex direction="column" m="xl" h="100%" justify="space-between" data-sentry-element="Flex" data-sentry-component="DesktopNavDrawer" data-sentry-source-file="AccountNav.tsx">
      <Flex gap="sm" align="center" style={{
      cursor: 'pointer'
    }} data-testid="accountnav-profile-button" className={navbarItem} onClick={() => {
      close();
      router.push(accountRoute(currentAccount.id).show);
    }} data-sentry-element="Flex" data-sentry-source-file="AccountNav.tsx">
        <SafeAvatarImage radius="sm" account={currentAccount} data-sentry-element="SafeAvatarImage" data-sentry-source-file="AccountNav.tsx" />
        <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="AccountNav.tsx">
          <Title tt="uppercase" order={1} mb="5px" data-sentry-element="Title" data-sentry-source-file="AccountNav.tsx">
            {currentAccount?.profile?.name}
          </Title>
          <Title order={6} tt="uppercase" data-sentry-element="Title" data-sentry-source-file="AccountNav.tsx">
            {currentAccount.type === AccountType.Personal ? 'Individual Account' : 'Business Account'}
          </Title>
        </Flex>
      </Flex>
      <Divider my="xl" data-sentry-element="Divider" data-sentry-source-file="AccountNav.tsx" />

      {/* <Button variant="outline" tt="uppercase" color="black" mb="100px">
        + Add Account
       </Button> */}

      <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="AccountNav.tsx">
        <MenuItem label="Community" onClick={() => handleClick('/')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <MenuItem label="Projects" onClick={() => handleClick('/projects')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <MenuItem label="Contacts" onClick={() => handleClick('/contacts')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <a href={meRoute().editProfile} style={{
        color: 'inherit',
        textDecoration: 'none'
      }}>
          <MenuItem label="Settings" onClick={() => close()} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        </a>
        {/* <MenuItem label="Find friends on Dome" disabled onClick={() => close()} /> */}
        <MenuItem label="Review Dome" onClick={() => handleClick('/review')} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
        <MenuItem label="Sign out" onClick={() => {
        handleSignOut();
        close();
      }} data-sentry-element="MenuItem" data-sentry-source-file="AccountNav.tsx" />
      </Flex>
    </Flex>;
}