import { graphql } from '~/generated/gql'

// miss naming - we really only suspend the account because Alex said we should'nt delete?

export const DeleteAccount = graphql(/* GraphQL */ `
  mutation DeleteAccount($accountId: String!) {
    deleteAccount(accountId: $accountId) {
      __typename
      ... on Account {
        id
      }
      ... on Error {
        message
      }
    }
  }
`)
