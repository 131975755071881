'use client';

import type React from 'react';
import { createContext } from 'react';
import { ZodObject } from 'zod';
export type FormValidationContextType = {
  schema: ZodObject<any>;
};
export const FormValidationContext = createContext<FormValidationContextType>({} as FormValidationContextType);
export function FormValidationProvider({
  schema,
  children
}: React.PropsWithChildren<FormValidationContextType>) {
  return <FormValidationContext.Provider value={{
    schema
  }} data-sentry-element="unknown" data-sentry-component="FormValidationProvider" data-sentry-source-file="FormValidationContext.tsx">{children}</FormValidationContext.Provider>;
}