import { type Actor, and, createActor, createMachine, not, raise, stateIn } from 'xstate'

import { AccountState } from '~/generated/graphql'
import { type AuthActor, createAuthMachine } from '~/machines/auth/machine'
import { MachineDebugger } from '~/machines/common/debugger'
import { startAppMachine } from '~/machines/common/useAppMachine'
import { appTrace, LocalStorageMock } from '~/utils'

import type { OnboardingEvents, OnboardingMachineContext } from '.'

export type OnboardingInterpreter = Actor<OnboardingMachine>
type OnboardingProvideParameters = Parameters<OnboardingMachine['provide']>
type OnboardingImplementation = OnboardingProvideParameters[0]
export type OnboardingMachine = ReturnType<OnboardingSystem['createOnboardingMachine']>

export class OnboardingSystem {
  private machineId: string
  public md: MachineDebugger
  private authActor: AuthActor
  machine: OnboardingMachine
  private storedPreStart?: (actor: OnboardingInterpreter) => void

  constructor({
    authActor,
    impl = {},
    preStart,
  }: {
    authActor?: AuthActor
    impl?: OnboardingImplementation
    preStart?: (actor: OnboardingInterpreter) => void
  }) {
    this.machineId = 'onboarding'
    this.authActor = authActor ?? this.stubAuthActor()
    this.md = new MachineDebugger({ id: this.machineId })
    this.machine = this.createOnboardingMachine().provide(impl)
    this.storedPreStart = preStart
  }

  stubAuthActor() {
    const actors = {
      authenticate: () => true,
    }
    const authMachineCtx = createAuthMachine({
      clientStorageType: 'custom',
      clientStorage: new LocalStorageMock(),
    })
    const authActor = createActor(authMachineCtx.machine.provide({ actors: actors as unknown as any }))
    authMachineCtx.debugger.start(authActor)
    return authActor
  }

  preStart(actor: OnboardingInterpreter) {
    // sometimes the onboarding process starts before the auth is complete
    // e.g. when the user opens the onboarding page directly by going to
    // /onboarding
    this.authActor.subscribe((_snapshot) => {
      this.md.logAction('onboarding: authActor snapshot updated')
      actor.send({ type: 'auth.update' })
      appTrace('onboarding: authActor snapshot update sent!')
    })
    this.storedPreStart?.(actor)
  }

  startActor() {
    const actor = startAppMachine({
      machine: this.machine,
      debugger: this.md,
      preStart: (a) => this.preStart(a),
    })
    return actor
  }

  createOnboardingMachine() {
    const md = this.md
    const machineId = this.machineId
    const authActor = this.authActor

    return createMachine(
      {
        /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOgHt8AjM9AJwgKgGJ0BXAF2xNYAcJ12YANoAGALqJQPMrFztcFSSAAeiAOwAmADQgAnogAsANgCMJAKwiTRowE4RAZgcmAHAbcBfDzrRY8hUgpqOgZ8ZjZObj4BYRMJJBBpWXlFBNUETR19BA0XEQsRW1sjJwcjDQcNcwMvHwwcAmJyKhp6RhYOLl5+QSENeKkZOQV8JXTMvUQjETUSEQ17UzUXaqMXDVqQXwaA5uC2sI7I7pihBwHEoZTRtPUNOZFHp+fHkyzEExMDe7UTEQMROY1EDbOYHDVvFt6v4mkFWqFwp0oj1hAYLklhqlQONtJMEC47CQ1o9yuDQeCNpDtjDAi0QowSDxaGQAGa4AA2YBIuAgnKYhGU7FE6KuIzG6lss1sLnJ30KGgM5ls7wQBlsBhI3yBlnMBOB0zUm2pjVp+wRjOZbM5JEwtDAAkYAFVYGBaExbfb5GFna6SLBWJhMHBYMKlBjruKENKjCRbOD1nYTNUZg4VQ41Pk-vNLA5-ppnEboSa9vCGUzWRyuR6Hd6XW7q16oD7aCRXczaKGEuGxbcMpLYzKwXLbAqlWminMwQ5qvYQRpAYW-MW4fSwhaK5yAKKC2joflgQWdwbJHvYwwaWYE3VrCpgpPmFV-Ez3AnlaYmexWcqLnawukHKBGWwMh2BkJhKCwABrI9LhPLEVEQcxCQMAxlmWAlwTVXFslzDUNAqEQjAMJNHnWFYfxpEtV0AnhgNA2B90PcQw1FeD0i1AoqjsYFARlbCPksIkjF1Ew1DUG9yUNTZ8DICA4CUY0AhYuCbjPBAAFoFRVTSHFjNQihcdNvgcQybCMCjl3-BFlMxVSEJyAwVRKEgKVsUSrAWcxdXMCzdhXACSHZGhrK7Vi7JxFVfnuAEviBYwiMqSk6iXPyrIZQhIAAQU6AAFZkADceVdGyI17XMXE1XVnBcNxzE+QyVQMac5ncJCUMqT4lV8v8zTLS1KxK097JsMxwXTEwxufN9H0Mgd1kKT4iOBJDutNUs13LK0uR5TlBrY9RChcprfkmjRprxSxbBIXIvPEsomqI1aqICzbKxtO0aybOs9vCxAihjBZiROj9TEajNNU0IilRWNZfik5LfzW6j1y27d2F3H7I3BMwTvMCpjE+M6kMfCarslf4rHJtRwXhqEUp69aaLomRMbK74SFcdDysqJwVQvWYNE+dNPIm5wjFpxSGeRzAyFQHhOUEVm1Oxkhcfx0wpuJi6Rxci9rDjJNBeWLwvCAA */
        types: {
          context: {} as OnboardingMachineContext,
          events: {} as OnboardingEvents,
        },
        context: ({ input }) => {
          md.logAction(`assigning onboardingMachine context:${JSON.stringify(input)}`)
          return {}
        },
        id: machineId,

        initial: 'onboarding',
        states: {
          onboarding: {
            initial: 'loading',
            on: {
              'auth.update': [
                {
                  target: '.needAuthProvider',
                  guard: and(['needProviderSignIn', not(stateIn('onboarding.needAuthProvider'))]),
                  actions: md.logAction('onboarding.loading - needProvider'),
                },
                {
                  target: '.photos',
                  guard: and(['isOnboardingPhotos', not(stateIn('onboarding.photos'))]),
                  actions: md.logAction('OnboardingPhotos'),
                },
                {
                  target: '.complete',
                  guard: and(['isComplete', not(stateIn('onboarding.complete'))]),
                  actions: md.logAction('onboarding.loading - complete'),
                },
                {
                  target: '.profileExtra',
                  guard: and(['isOnboardingProfileExtra', not(stateIn('onboarding.profileExtra'))]),

                  actions: md.logAction('OnboardingProfileExtra'),
                },
                {
                  target: '.profile',
                  guard: and(['needProfileCreation', not(stateIn('onboarding.profile'))]),
                  actions: md.logAction('onboarding.loading - needProfileCreation'),
                },
              ],
            },

            states: {
              loading: {
                entry: [md.logAction('loading entry'), raise({ type: 'auth.update' })],
                exit: [md.logAction('loading exit')],
              },
              needAuthProvider: {
                entry: [md.logAction('needauthprov entry'), 'triggerProviderSignIn'],
                exit: [md.logAction('needauthprov exit')],
              },
              profile: {
                entry: [md.logAction('profile entry'), 'ensureProfileUrl'],
                exit: [md.logAction('profile exit')],
                initial: 'idle',
                states: {
                  idle: {
                    on: {
                      next: {
                        actions: [md.logAction('profile.next')],
                        target: 'creatingUser',
                      },
                    },
                  },
                  creatingUser: {
                    entry: [md.logAction('cruser entry'), 'createUser'],
                    exit: [md.logAction('cruser exit')],
                    on: {
                      'creatingUser.success': {
                        actions: md.logAction('cruser success'),
                      },
                      'creatingUser.error': {
                        target: 'idle',
                        actions: md.logAction('cruser fail'),
                      },
                    },
                  },
                },
              },
              profileExtra: {
                entry: [md.logAction('profileExtra entry'), 'ensureProfileExtraUrl'],
                exit: [md.logAction('profileExtra exit')],
                on: {
                  next: {
                    actions: [md.logAction('profileExtra.next'), 'updateUser'],
                  },
                },
              },
              photos: {
                entry: [md.logAction('photos entry'), 'ensurePhotosUrl'],
                exit: [md.logAction('photos exit')],
                on: {
                  back: {
                    target: 'profile',
                    actions: 'reset',
                  },
                  next: {
                    actions: [md.logAction('photos.next'), 'updateUser'],
                  },
                },
              },
              complete: {
                entry: [md.logAction('complete entry')],
                exit: [md.logAction('complete exit')],
                type: 'final',
              },
            },
          },
        },
      },
      {
        guards: {
          needProviderSignIn: () =>
            authActor.getSnapshot().matches('provider.signedOut') && authActor.getSnapshot().matches('api.signedOut'),

          needProfileCreation: () => {
            return authActor.getSnapshot().matches('registration.onboarding.noUser')
          },
          isOnboardingProfileExtra: () =>
            authActor.getSnapshot().context.account?.state === AccountState.OnboardingProfileExtra,

          isOnboardingPhotos: () => authActor.getSnapshot().context.account?.state === AccountState.OnboardingPhotos,

          isComplete: () => authActor.getSnapshot().context.account?.state === AccountState.Active,
        },
      }
    )
  }
}
