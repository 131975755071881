export type PageArgs = {
  after?: string
  first: number
}

export type PageInfo = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor?: string | null
  endCursor?: string | null
}

export type PageContext = {
  pageInfo: PageInfo | null
  pageArgs: PageArgs
}

export type NumberedPageContext = {
  page: number
  context: PageContext
}

export type PageArgsList = Record<number, PageArgs>
export type PageInfoList = Record<number, PageInfo>

export type RelayConnectionContext = {
  pageArgsList: PageArgsList
  pageInfoList: PageInfoList
  resultsPerPage: number
  lastLoadedPage: number
  totalCount?: number
  needsNextPage: boolean
}

const DEFAULT_RESULTS_PER_PAGE = 10
export const initRelayConnectionContext = (): RelayConnectionContext => ({
  pageArgsList: {
    1: { first: DEFAULT_RESULTS_PER_PAGE },
  },
  pageInfoList: {},
  resultsPerPage: DEFAULT_RESULTS_PER_PAGE,
  needsNextPage: true,
  lastLoadedPage: 0,
})
