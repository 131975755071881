'use client';

import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { Affix, Button, Transition, rem } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
export function ScrollToTop() {
  const [scroll, scrollTo] = useWindowScroll();
  return <Affix position={{
    bottom: rem(2)
  }} data-sentry-element="Affix" data-sentry-component="ScrollToTop" data-sentry-source-file="ScrollToTopAffix.tsx">
      <Transition transition="slide-up" mounted={scroll.y > 20} data-sentry-element="Transition" data-sentry-source-file="ScrollToTopAffix.tsx">
        {transitionStyles => <Button color="var(--mantine-color-gray-7)" ml="md" p="0" variant="subtle" style={{
        ...transitionStyles,
        height: '100px',
        marginBottom: '0'
      }} onClick={() => scrollTo({
        y: 0
      })}>
            <ArrowUpCircleIcon width={60} style={{
          opacity: 0.8
        }} />
          </Button>}
      </Transition>
    </Affix>;
}