import { notifications } from '@mantine/notifications';
import { useMutation } from '@urql/next';
import { useState } from 'react';
import { LikeVideo } from '~/app/(me)/_lib';
import { type Account, LikeTargetAction, TrimmedAccountFragment } from '~/generated/graphql';
export const useVideoLikeHandler = ({
  account,
  hasLiked
}: {
  account: TrimmedAccountFragment;
  hasLiked: boolean;
}) => {
  const [solidHeart, setSolidHeart] = useState(hasLiked);
  const [, updateLikeVideo] = useMutation(LikeVideo);
  const handleLikeVideoOnClick = async (videoId: string) => {
    setSolidHeart(true);
    const result = await updateLikeVideo({
      fileId: videoId,
      action: LikeTargetAction.Like
    });
    if (result.data?.likeVideo?.__typename === 'VideoItem') {
      notifications.show({
        title: 'Yay!',
        message: `We applaud the love you're sharing!`
      });
    } else if (result?.data?.likeVideo?.__typename === 'Error') {
      notifications.show({
        title: 'Something went wrong',
        message: result.error?.message,
        color: 'warning'
      });
    }
  };
  const handleUnLikeVideoOnClick = async (videoId: string) => {
    setSolidHeart(false);
    const result = await updateLikeVideo({
      fileId: videoId,
      action: LikeTargetAction.Dislike
    });
    if (result.data?.likeVideo?.__typename === 'Error') {
      notifications.show({
        title: 'Something went wrong',
        message: result.error?.message,
        color: 'warning'
      });
    }
  };
  const getPlatform = (platform: string, urlId: string) => {
    return platform === 'youtube' ? `https://www.youtube.com/embed/${urlId}?rel=0&iv_load_policy=3&controls=0&showinfo=0&color=white&disablekb=1` : `https://player.vimeo.com/video/${urlId}?transcript=false&pip=false&keyboard=false&title=false`;
  };
  return {
    solidHeart,
    handleLikeVideoOnClick,
    handleUnLikeVideoOnClick,
    getPlatform
  };
};