'use client';

import { Suspense, useContext, useEffect } from 'react';
import type { AnyStateMachine, StateFrom, StateValue } from 'xstate';
import { type Resource, appTrace, useSuspensefulGetter } from '~/utils';
import { NoSsr } from '../context';
import { AuthActorContext } from './AuthProvider';
function WaitForAuthInner({
  children,
  resource,
  context
}: {
  children: React.ReactNode;
  resource: Resource<StateValue>;
  context?: string;
}) {
  const value = resource.read();
  useEffect(() => {
    appTrace(`W4StableAuthState ${context}:${JSON.stringify(value)}`);
  }, [value, context]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return <>{children}</>;
}
export function stableAuthStateMatcher(state: StateFrom<AnyStateMachine>): boolean {
  return ['api.unknown', 'provider.unknown',
  // 'registration.unknown', - stable state
  'registration.loading'].every(s => !state.matches(s));
  // this one is not working - matches right after token restore
  // return [
  //   'api.signedOut',
  //   'registration.onboarding',
  //   'registration.complete'
  // ].some((s) => state.matches(s))
}
export function WaitForStableAuthState({
  children,
  context
}: {
  children: React.ReactNode;
  context?: string;
}) {
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const resource = useSuspensefulGetter(authActor, stableAuthStateMatcher, s => s.value);
  return <NoSsr data-sentry-element="NoSsr" data-sentry-component="WaitForStableAuthState" data-sentry-source-file="WaitForStableAuthState.tsx">
      <Suspense data-sentry-element="Suspense" data-sentry-source-file="WaitForStableAuthState.tsx">
        <WaitForAuthInner context={context} resource={resource} data-sentry-element="WaitForAuthInner" data-sentry-source-file="WaitForStableAuthState.tsx">
          {children}
        </WaitForAuthInner>
      </Suspense>
    </NoSsr>;
}