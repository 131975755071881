import { graphql } from '~/generated/gql'

export const updateCurrentUser = graphql(/* GraphQL */ `
  mutation updateCurrentUser($user: UserUpdateInput!) {
    updateCurrentUser(user: $user) {
      __typename
      ... on User {
        __typename
        id
        state
        createdAt
        settings {
          sendEmailNotification
        }
      }
      ... on Error {
        message
      }
    }
  }
`)
