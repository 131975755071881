'use client';

import { Suspense, useContext } from 'react';
import type { StateValue } from 'xstate';
import { type Resource, appTrace, useSuspensefulGetter } from '~/utils';
import { NoSsr } from '../context';
import { AuthActorContext } from './AuthProvider';
import { RequireRegistration } from './RequireRegistration';
function EnforceOnboardedInner({
  children,
  resource
}: {
  children: React.ReactNode;
  resource: Resource<StateValue>;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const authState = resource.read() as unknown as any;
  appTrace(`EnforceOnboadedInner: ${JSON.stringify(authState)}`);
  if (authState.registration === 'complete') return <>{children}</>;
  return <RequireRegistration data-sentry-element="RequireRegistration" data-sentry-component="EnforceOnboardedInner" data-sentry-source-file="EnforceOnboarded.tsx" />;
}
export function EnforceOnboarded({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const resource = useSuspensefulGetter(authActor, s => s.matches('api.signedOut') || s.matches('registration.onboarding') || s.matches('registration.complete'), s => s.value);
  return <NoSsr data-sentry-element="NoSsr" data-sentry-component="EnforceOnboarded" data-sentry-source-file="EnforceOnboarded.tsx">
      <Suspense data-sentry-element="Suspense" data-sentry-source-file="EnforceOnboarded.tsx">
        <EnforceOnboardedInner resource={resource} data-sentry-element="EnforceOnboardedInner" data-sentry-source-file="EnforceOnboarded.tsx">{children}</EnforceOnboardedInner>
      </Suspense>
    </NoSsr>;
}