'use client';

import 'swiper/css';
import { Box, Card, CardSection, Flex } from '@mantine/core';
import { useRouter } from 'next/navigation';
import { useContext } from 'react';
import { PeerAccountContext, StyledButton, useAppAuth } from '~/app/_components';
import { ImagesCarousel } from '~/app/_components/carousel';
import { accountRoute } from '~/utils';
import { AccountAvatarBlock } from '../AccountAvatarBlock';
import { ConnectButton } from '../common/ConnectButton';
import { ReportUserMenu } from '../common/ReportUserMenu';
import { mobileProfileInfoWrapper } from './styles.css';
export function MobileProfileCard() {
  const {
    account,
    navigateToProfile
  } = useContext(PeerAccountContext);
  const {
    isSignedIn
  } = useAppAuth();
  const router = useRouter();
  const routeInfo = {
    show: accountRoute(account.id).show
  };
  return <>
      <Flex justify="center" mb="xl" data-sentry-element="Flex" data-sentry-source-file="MobileProfileCard.tsx">
        <Card p={0} w="100%" style={{
        borderTop: '1px solid #efefef',
        borderBottom: '1px solid #efefef'
      }} data-testid="profile-card-mobile" data-sentry-element="Card" data-sentry-source-file="MobileProfileCard.tsx">
          <CardSection h="100%" data-testid="profile-card" my="20px" mx="15px" data-sentry-element="CardSection" data-sentry-source-file="MobileProfileCard.tsx">
            <Flex direction="column" h="100%" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="MobileProfileCard.tsx">
              <Box onClick={navigateToProfile} mx="-15px" data-sentry-element="Box" data-sentry-source-file="MobileProfileCard.tsx">
                <ImagesCarousel data-sentry-element="ImagesCarousel" data-sentry-source-file="MobileProfileCard.tsx" />
              </Box>

              <Flex mt="md" justify="space-between" align="flex-start" data-sentry-element="Flex" data-sentry-source-file="MobileProfileCard.tsx">
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <div className={mobileProfileInfoWrapper} onClick={navigateToProfile}>
                  <AccountAvatarBlock account={account} data-sentry-element="AccountAvatarBlock" data-sentry-source-file="MobileProfileCard.tsx" />
                </div>
                <Flex direction="column" gap="5px" ml="md" justify="flex-end" data-sentry-element="Flex" data-sentry-source-file="MobileProfileCard.tsx">
                  {isSignedIn ? <ConnectButton variant="xSmall" disablePadding={true} noPadding={true} /> : null}
                  <StyledButton text="VIEW FOLIO" width="82.2px" noPadding={true} type="black" size="xSmall" shape="square" disablePadding={true} onClick={() => router.push(routeInfo.show)} testId="view-folio-button" slimHeight={true} data-sentry-element="StyledButton" data-sentry-source-file="MobileProfileCard.tsx" />
                  <Flex justify="flex-end" data-sentry-element="Flex" data-sentry-source-file="MobileProfileCard.tsx">
                    <ReportUserMenu data-sentry-element="ReportUserMenu" data-sentry-source-file="MobileProfileCard.tsx" />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </CardSection>
        </Card>
      </Flex>
    </>;
}