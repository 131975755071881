import { Suspense, useContext } from 'react';
import React from 'react';
import { RelayPaginationContext } from '~/app/(accounts)/_components';
import { type Resource, appTrace, useSuspensefulGetter } from '~/utils';
import { NoSsr } from '../context';
function WaitForTotalCountInner({
  children,
  resource
}: {
  children: React.ReactNode;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  resource: Resource<any>;
}) {
  appTrace(`WaitForTotalCount: ${JSON.stringify(resource.read())}`);
  return <>{children}</>;
}
export function WaitForTotalCount({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    actor
  } = useContext(RelayPaginationContext);
  const resource = useSuspensefulGetter(actor, s => s.context.totalCount !== undefined, s => s.value);
  return <NoSsr data-sentry-element="NoSsr" data-sentry-component="WaitForTotalCount" data-sentry-source-file="WaitForTotalCount.tsx">
      <Suspense data-sentry-element="Suspense" data-sentry-source-file="WaitForTotalCount.tsx">
        <WaitForTotalCountInner resource={resource} data-sentry-element="WaitForTotalCountInner" data-sentry-source-file="WaitForTotalCount.tsx">{children}</WaitForTotalCountInner>
      </Suspense>
    </NoSsr>;
}