import { graphql } from '~/generated'

export const DeclineFriendship = graphql(/* GraphQL */ `
  mutation declineFriendship($requesterId: String!) {
    declineFriendship(requesterId: $requesterId) {
      __typename
      ... on FriendshipWithAccount {
        friendship {
          __typename
          id
          state
          requester {
            __typename
            id
            createdAt
            connectionStatus
            profile {
              name
            }
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`)
