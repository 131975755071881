'use client';

import { Flex, Text, Title } from '@mantine/core';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import { getPronoun } from '../../_lib';
import { ReportUserMenu } from '../common';
import { AvatarSizes, SafeAvatarImage } from '~/app/_components';
import { AppLayoutContext, IdentityContext, PeerAccountContext } from '~/app/_components/context';
import { accountBrand, accountProfessions } from '~/utils';
import { AccountTier } from '~/generated/graphql';
import { ProBadge } from './DesktopProfileCard';
import { SocialInfo } from '../../accounts/[id]/[[...pageParams]]/_components';
export function AccountAvatarBlockDektop({
  centerTitle = false
}: {
  centerTitle?: boolean;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const pathname = usePathname();
  const {
    currentAccount
  } = useContext(IdentityContext);
  const {
    account
  } = useContext(PeerAccountContext);
  const showReportMenu = currentAccount?.id !== account.id;
  const size: AvatarSizes = mobile ? !pathname.includes('/accounts') ? 'sm' : 'md' : 'xmd';
  const splitName = account.profile?.name ? account.profile?.name.trim().replace(/\s+/g, ' ').split(' ') : [];
  function nameElementSize(name: string | undefined | null): string {
    // Split the name by spaces
    const allNames = name?.split(' ') ?? [];

    // Find the longest name by comparing lengths
    const longestName = allNames.reduce((longest, current) => current.length > longest.length ? current : longest, '');
    if (longestName.length > 8) return '45px';else return '70px';
  }
  return <Flex align="center" gap="30px" direction="column" w="100%" data-sentry-element="Flex" data-sentry-component="AccountAvatarBlockDektop" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
      <Flex direction="column" justify="flex-start" w="100%" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
        <Flex gap="xl" justify={centerTitle ? 'center' : 'flex-start'} align="flex-start" w="100%" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
          <Flex direction="column" data-testid="profile-title" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
            {splitName.map((name, index) => <Title key={index} tt="uppercase" fz={nameElementSize(account?.profile?.name)} lh="77%">
                {name}
              </Title>)}
          </Flex>
        </Flex>
      </Flex>
      <Flex gap="md" align="center" justify={centerTitle ? 'center' : 'flex-start'} w="100%" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
        <SafeAvatarImage account={account} radius={size} data-sentry-element="SafeAvatarImage" data-sentry-source-file="AccountAvatarBlockDesktop.tsx" />

        <Flex direction="column" gap="5px" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
          <Flex gap="xs" align="center" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
            {account.tier === AccountTier.Pro ? <ProBadge /> : null}
            {account.profile?.pronoun ? <Text size="md">{getPronoun(account.profile?.pronoun)}</Text> : null}
          </Flex>
          {account.profile?.brand ? <Title order={4} tt="uppercase">
              {accountBrand(account)}
            </Title> : null}
          {account.professions && account.professions.length >= 1 ? <Title order={4} tt="uppercase">
              {accountProfessions(account)}
            </Title> : null}
          {account.profile?.location ? <Title order={4} tt="uppercase">
              {account.profile?.location}
            </Title> : null}
        </Flex>
      </Flex>

      <Flex direction="row" gap="sm" w="100%" align="center" justify="space-between" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
        <Flex align="center" gap="sm" data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
          <SocialInfo account={account} data-sentry-element="SocialInfo" data-sentry-source-file="AccountAvatarBlockDesktop.tsx" />
        </Flex>
        <Flex data-sentry-element="Flex" data-sentry-source-file="AccountAvatarBlockDesktop.tsx">
          {showReportMenu ? <Flex pt="4px">
              <ReportUserMenu />
            </Flex> : null}
        </Flex>
      </Flex>
    </Flex>;
}