'use client';

import { useSelector } from '@xstate/react';
import { startTransition, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { RelayPaginationContext } from '~/app/(accounts)/_components/RelayPaginationContext';
export function ScrollFetcher({
  children,
  id
}: React.PropsWithChildren<{
  id?: string;
}>) {
  // }: React.PropsWithChildren & { ctx: T }) {
  //   const { actor }: { actor: RelayConnectionActor } = useContext(ctx)

  const {
    actor,
    id: relayPaginationId
  } = useContext(RelayPaginationContext);
  const inViewId = id || relayPaginationId || 'unknown-inview';
  const searchState = useSelector(actor, s => s);
  function notifyMachine(inView: boolean) {
    // console.log(
    //   'xstate projects notify machine called',
    //   inView,
    //   actor.getSnapshot(),
    // )
    console.log('notifying machine, ', inViewId, inView, searchState.context.lastLoadedPage);
    if (inView) startTransition(() => actor.send({
      type: 'NEXT_PAGE'
    }));
  }
  const {
    ref
  } = useInView({
    rootMargin: '100px 0px',
    triggerOnce: true,
    initialInView: false,
    onChange: notifyMachine
  });
  const inViewKey = `results-page-${inViewId}${searchState.context.lastLoadedPage}`;
  return <>
      {children}
      <div key={inViewKey} ref={ref} />
    </>;
}