'use client';

import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from '@xstate/react';
import { useContext } from 'react';
import { LocalConfigContext } from '../context';
import { AuthActorContext } from './AuthProvider';
import { stableAuthStateMatcher } from './WaitForStableAuthState';

// NOTE: This state triggers when a new user managed to sign in with auth0
// but e.g. closed the window without creating a user on the backend
// they could be on any page and we need to redirect such users to /onboarding
// ALL states need to be matched
const NoUserState = ['api.signedOut', 'provider.signedIn', 'registration.unknown'];
export function useAppAuth() {
  const {
    flags
  } = useContext(LocalConfigContext);
  // appTrace({ disableonboarding: flags['disableOnboarding'] })
  const {
    loginWithPopup,
    logout
  } = useAuth0();
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const isSignedIn = useSelector(authActor, state => {
    return state.matches('api.signedIn');
  });
  const needsOnboarding = useSelector(authActor, state => {
    if (flags.disableOnboarding) return false;
    return state.matches('registration.onboarding') || NoUserState.every(s => state.matches(s));
  });
  // could be partially created as well
  const isUserCreated = useSelector(authActor, state => {
    return state.matches('registration.onboarding') || state.matches('registration.complete');
  });
  const allowsOnboardingCompletion = useSelector(authActor, state => {
    return flags.disableOnboarding ? false : state.matches('registration.complete');
  });
  const isStableAuthState = useSelector(authActor, stableAuthStateMatcher);

  // appTrace('useAppAuth', { isUserCreated, isStableAuthState })

  return {
    handleSignIn: () => loginWithPopup(),
    handleSignUp: () => loginWithPopup({
      authorizationParams: {
        screen_hint: 'signup'
      }
    }),
    handleSignOut: () => {
      logout();
      authActor.send({
        type: 'SIGNOUT'
      });
    },
    isSignedIn,
    isUserCreated,
    isStableAuthState,
    allowsOnboardingCompletion,
    needsOnboarding
  };
}