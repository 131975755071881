module.exports = {
  // environment: process.env.NEXT_PUBLIC_APP_ENV as Environment,
  nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV || process.env.NODE_ENV,
  logLevel: process.env.NEXT_PUBLIC_LOG_LEVEL || process.env.LOG_LEVEL,
  maintenanceMode: {
    enabled: process.env.NEXT_PUBLIC_MAINTENANCE_MODE_ENABLED === 'true',
    expectedReturn: new Date(process.env.NEXT_PUBLIC_MAINTENANCE_MODE_EXPECTED_RETURN),
  },

  auth0: {
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    apiDomain: process.env.NEXT_PUBLIC_AUTH0_API_DOMAIN,
  },

  hotjar: {
    id: Number.parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID),
    sv: Number.parseInt(process.env.NEXT_PUBLIC_HOTJAR_SV),
  },

  api: {
    graphql: process.env.NEXT_PUBLIC_GRAPHQL_END_POINT,
    companion: process.env.NEXT_PUBLIC_COMPANION_END_POINT,
    // minio: process.env.NEXT_PUBLIC_MINIO_HOSTNAME,
  },

  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },

  flagsmith: {
    clientSideKey: process.env.NEXT_PUBLIC_FLAGSMITH_CLIENT_KEY,
  },

  // intlRedirects: [
  //   {
  //     source: '/my-key-results',
  //     destination: '/my-things',
  //   },
  // ],
  //
  // intlRoutes: [
  //   {
  //     source: '/pt-BR/perfil',
  //     destination: '/profile',
  //     locale: 'pt-BR',
  //   },
  // ],
}
