'use client';

import { useRouter } from 'next/navigation';
import { PropsWithChildren, useEffect } from 'react';
import { WaitForStableAuthState } from './WaitForStableAuthState';
import { useAppAuth } from './hooks';
function EnforceAuthenticationInner({
  children
}: PropsWithChildren) {
  const {
    isSignedIn
  } = useAppAuth();
  const router = useRouter();
  useEffect(() => {
    if (!isSignedIn) router.push('/');
  }, [isSignedIn, router]);
  return isSignedIn ? <>{children}</> : null;
}
export function EnforceAuthentication({
  children
}: PropsWithChildren) {
  return <WaitForStableAuthState data-sentry-element="WaitForStableAuthState" data-sentry-component="EnforceAuthentication" data-sentry-source-file="EnforceAuthentication.tsx">
      <EnforceAuthenticationInner data-sentry-element="EnforceAuthenticationInner" data-sentry-source-file="EnforceAuthentication.tsx">{children}</EnforceAuthenticationInner>
    </WaitForStableAuthState>;
}