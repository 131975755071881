import { graphql } from '~/generated'

export const SendMessage = graphql(/* GraphQL */ `
  mutation sendMessage($recipientId: String!, $message: MessageCreateInput!) {
    sendMessage(message: $message, recipientId: $recipientId) {
      __typename
      ... on Account {
        id
        messages {
          id
          recipientId
          content
        }
        connectionStatus
      }
      ... on Error {
        message
      }
    }
  }
`)
