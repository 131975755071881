import { type Exchange, makeOperation } from '@urql/next'
import type { DocumentNode } from 'graphql'
import { map, pipe } from 'wonka'
import { URL } from 'url'

/**
 * Function to extract the query names from a GQL document
 *
 * @param  doc - DocumentNode from parsed GQL query
 * @returns An array of operation names
 */
export function getQueryNames(doc: DocumentNode): string[] {
  return doc.definitions
    .map<string>((definition) => {
      const name = definition.kind === 'OperationDefinition' ? definition.name?.value : ''
      return name || ''
    })
    .filter((n) => n)
}
export const opNameExchange: Exchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      map((op) => {
        let url: string
        try {
          const parsedUrl = typeof window !== 'undefined' ? new window.URL(op.context.url) : new URL(op.context.url)
          parsedUrl.searchParams.set('t', op.kind[0])
          parsedUrl.searchParams.set('o', getQueryNames(op.query).join(','))
          url = parsedUrl.toString()
          // }
        } catch (_e) {
          console.error('error in opNameExchange', _e)
          url = op.context.url
        }
        return makeOperation(op.kind, op, { ...op.context, url })
      }),
      forward
    )
}
