'use client';

import { Box, Container, type ContainerProps, Flex, type FlexProps } from '@mantine/core';
import { usePathname } from 'next/navigation';
import type React from 'react';
import { useContext } from 'react';
import { AppLayoutContext, appShellContentBranded, appShellContentDefault, appShellContentTransparent, appShellContentWhite } from '~/app/_components';
type WrapperVariant = 'branded' | 'default' | 'subtle' | 'side-branded';
type WrapperVariantStyles = Record<WrapperVariant, string>;
type WrapperProps = FlexProps & ContainerProps & {
  children: React.ReactNode;
  fullHeight?: boolean;
  variant?: WrapperVariant;
  uniformColor?: boolean;
  removeMobilePadding?: boolean;
};

// NOTE:
// This component can only be used as the highest-level wrapper for main content
//
// Features:
// * different color variants for outer space and primary content
// * expanded / full height that takes into account the header
// * collapsed padding on mobile

// WARNING
// I do not understand why mih needs to be set at all for the inner Flex component
// The parent already has h/mih set and it should be enough but it not
export function AppShellContent(props: WrapperProps) {
  const outerVariants: WrapperVariantStyles = {
    subtle: '',
    default: appShellContentDefault,
    branded: appShellContentBranded,
    'side-branded': appShellContentBranded
  };
  const innerVariants: WrapperVariantStyles = {
    subtle: appShellContentWhite,
    default: appShellContentWhite,
    branded: appShellContentWhite,
    'side-branded': appShellContentTransparent
  };
  const mih = props.fullHeight ? 'calc(100vh - var(--mantine-header-height, 0px))' : undefined;
  function AppShellContentInner(props: WrapperProps) {
    const {
      mobile
    } = useContext(AppLayoutContext);
    const className = innerVariants[props.variant || 'default'] || outerVariants[props.variant || 'default'];
    const p = props.p ? props.p : '0px';
    const containerProps = {
      h: props.fullHeight ? '100%' : undefined,
      p: mobile && props.removeMobilePadding ? '0px' : p,
      size: props.size ? props.size : undefined,
      // sizes: props.sizes ? props.sizes : undefined,
      // this is needed for AppShell when the layout is not fixed
      mih
    };
    return <>
        <Container {...containerProps} className={className} data-sentry-element="Container" data-sentry-source-file="AppShellContent.tsx">
          <Flex gap={props.gap || 'unset'} direction={props.direction || 'column'} justify={props.justify || 'center'} align={props.align || 'center'} h={props.fullHeight ? '100%' : 'unset'} mih={mih} data-sentry-element="Flex" data-sentry-source-file="AppShellContent.tsx">
            {props.children}
          </Flex>
        </Container>
      </>;
  }

  // WARNING:
  // the height of this container can't be 100vh
  // because it needs to account for a possible header
  return <Box className={outerVariants[props.variant || 'default']} h={props.fullHeight ? '100%' : ''} w="100%" data-sentry-element="Box" data-sentry-component="AppShellContent" data-sentry-source-file="AppShellContent.tsx">
      <AppShellContentInner {...props} data-sentry-element="AppShellContentInner" data-sentry-source-file="AppShellContent.tsx" />
    </Box>;
}