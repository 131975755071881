import { notifications } from '@mantine/notifications';
import { useMutation } from '@urql/next';
import { useState } from 'react';
import { LikeImage } from '~/app/(me)/_lib';
import { FileTypeLike, LikeTargetAction } from '~/generated/graphql';
export const useLikeHandler = ({
  hasLiked
}: {
  hasLiked: boolean;
}) => {
  const [solidHeart, setSolidHeart] = useState(hasLiked);
  const [, updateLikeImage] = useMutation(LikeImage);
  const handleLikePhotoOnClick = async (folioId: string) => {
    setSolidHeart(true);
    const result = await updateLikeImage({
      fileId: folioId,
      type: FileTypeLike.Folio,
      action: LikeTargetAction.Like
    });
    if (result.data?.likeImage?.__typename === 'FolioItem') {
      notifications.show({
        title: 'Yay!',
        message: `We applaud the love you're sharing!`
      });
    } else if (result?.data?.likeImage?.__typename === 'Error') {
      notifications.show({
        title: 'Something went wrong',
        message: result.error?.message,
        color: 'warning'
      });
    }
  };
  const handleUnLikeOnClick = async (folioId: string) => {
    setSolidHeart(false);
    const result = await updateLikeImage({
      fileId: folioId,
      type: FileTypeLike.Folio,
      action: LikeTargetAction.Dislike
    });
    if (result.data?.likeImage?.__typename === 'Error') {
      notifications.show({
        title: 'Something went wrong',
        message: result.error?.message,
        color: 'warning'
      });
    }
  };
  return {
    solidHeart,
    setSolidHeart,
    handleLikePhotoOnClick,
    handleUnLikeOnClick
  };
};