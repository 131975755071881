'use client';

import { UrqlProvider } from '@urql/next';
import { PropsWithChildren, useContext, useMemo } from 'react';
import { AuthActorContext } from '~/app/_components';
import { configureUrqlClient } from '~/graphql/browserClient';
export function BackendApiProvider({
  children
}: PropsWithChildren) {
  const {
    actor: authActor
  } = useContext(AuthActorContext);
  const {
    client,
    ssr
  } = useMemo(() => configureUrqlClient({
    authActor
  }), []);
  return <UrqlProvider client={client} ssr={ssr} data-sentry-element="UrqlProvider" data-sentry-component="BackendApiProvider" data-sentry-source-file="BackendApiContext.tsx">
      {children}
    </UrqlProvider>;
}