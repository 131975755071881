import { graphql } from '~/generated'

export const GetProfessions = graphql(`
  query GetProfessions {
    __typename
    professions {
      __typename
      id
      name
    }
  }
`)
