import { Flex, Stack, Text } from '@mantine/core';
import { AccountAvatarBlock } from '~/app/(accounts)/_components';
import { IdentityContext, ImagesCarousel, PeerAccountContext, StyledButton } from '~/app/_components';
import { meRoute } from '~/utils';
import { useContext } from 'react';
import { SocialInfo } from '../shared';
export function ProfileMobile() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;

  // const hasFolioItems = account.folioItems && account.folioItems.length > 0

  return <Stack m="sm" gap="lg" data-sentry-element="Stack" data-sentry-component="ProfileMobile" data-sentry-source-file="ProfileMobile.tsx">
      <ImagesCarousel data-sentry-element="ImagesCarousel" data-sentry-source-file="ProfileMobile.tsx" />

      <AccountAvatarBlock account={account} data-sentry-element="AccountAvatarBlock" data-sentry-source-file="ProfileMobile.tsx" />

      <SocialInfo account={account} data-sentry-element="SocialInfo" data-sentry-source-file="ProfileMobile.tsx" />

      <Text c="dimmed" data-testid="about-mobile-section" size="md" data-sentry-element="Text" data-sentry-source-file="ProfileMobile.tsx">
        {account.profile?.about}
      </Text>

      {isCurrentUserProfile ? <Flex mb="50px">
          <StyledButton type="black" text="EDIT PROFILE" fz="15px" href={meRoute().editProfile} width="100%" shape="square" />
        </Flex> : null}
    </Stack>;
}