import type { AuthContext } from '../context'
import { ACCESS_TOKEN_KEY } from '../machine'
import type { StorageGetter } from '../types'

export async function restoreApiToken(args: {
  context: AuthContext
  storageGetter: StorageGetter
}): Promise<{ token?: string }> {
  const token = await args.storageGetter(ACCESS_TOKEN_KEY)
  if (!token) throw new Error('could not restore api token')
  return { token: token }
}
