import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.4.1_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F_components%2Flayout%2Fheader%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82VyW7bMBCG734KAkUBuwAN2U3sWLkUKQrUQE9t78aIHEtMKFIgKS8t8u4lqcirHAfIpb6JnOWbf4bjoXVbiXaxrKX8jsDRLBbb2WMxnSbkb48QLmwlYZuSpcTNvT94rK0Tyy1lWjlULiW2AoY0Q7dGVMECpMgVFQ5LmxLmbdCE4wJFXnj7aVLFQBmwp9zoWnEfS2qTEqEKNMLd9557wxesIiL9wKX7hcwJrR6kZk8t4qgb8TTTBaAKOBcqT0lCkvC9LrwJjeWkROm1gSoch6gpGXXWHu6odWA6oH8GiC7q8X9BjYp3CK1zfcT6%2BV2sZ5m7yrhaxPiQs0RVz0OelvCmm%2FACUQ6V%2FwTJ%2BmODJflEVmD6lJagnFCewF%2FhYBAsHW4c5ci0gdDBgKXwFdZWklGSfDwEzmqTo%2FnaKNBC30boQw32DtzA2jtIbfHBqdZj0nhoKxoag9JzrSKR9M8jJTdJbMarUsFJ3kaK0RUpzsTdq5gMJ%2BPbK%2B5X9kEj2EXB9%2FUAY35buLmfpraiaaynBJMLRU0T741c%2B7gWwbDit08%2FV1W969HsUCuaaed0uYs%2BfWvw5l19k1getL9pA6uNDWuv0qLR5HRoWvMsmi%2F9CNH1i2iZlkfPl8VxqT3jThu297Lij5%2FV0fh4PhSsMjBhQloXfo2rwyUt9ApNdDzf6B9m8RdCfCmRCyCWGf83QUBx0i9hQ9eCu8IvksldtRnEKKfj22a6i7dHFSXDWWxEGJ%2Fn3vM%2FfDm%2BE8wGAAA%3D%22%7D"
export var accountIcon = 'styles_accountIcon__y9jh777';
export var burger = 'styles_burger__y9jh77b';
export var burgerContent = 'styles_burgerContent__y9jh775';
export var closeButton = 'styles_closeButton__y9jh77c';
export var drawerCloseBtn = 'styles_drawerCloseBtn__y9jh776';
export var fullHeader = 'styles_fullHeader__y9jh770';
export var headerElement = 'styles_headerElement__y9jh77a';
export var headerLeftSectionBlock = 'styles_headerLeftSectionBlock__y9jh771';
export var headerLogoBlock = 'styles_headerLogoBlock__y9jh773';
export var headerRightSectionBlock = 'styles_headerRightSectionBlock__y9jh772';
export var menuItem = 'styles_menuItem__y9jh778';
export var menuItems = 'styles_menuItems__y9jh774';
export var navbarItem = 'styles_navbarItem__y9jh77d';
export var searchTextInput = 'styles_searchTextInput__y9jh779';