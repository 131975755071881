'use client';

import React, { createContext, useMemo } from 'react';
import { NotificationReadState } from '~/generated/graphql';
import { mkRelayConnectionMachineCtx, RelayConnectionActor, useAppMachine } from '~/machines';
export type NotificationsContextType = {
  actor: RelayConnectionActor;
  query: NotificationReadState;
};
export const AccountNotificationsContext = createContext<NotificationsContextType>({} as NotificationsContextType);
export function AccountNotificationsProvider({
  children,
  unread = true
}: React.PropsWithChildren<{
  unread?: boolean;
}>) {
  // NOTE:
  // Currently we don't allow users to change any parameters for notifications query
  // instead we create two separate contexts for unread and read notifications
  // Because of that we don't subscribe to dependencies anywhere in this provider
  //
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const ctx = useMemo(() => mkRelayConnectionMachineCtx({
    id: `notifications-${unread ? 'unread' : 'read'}`
  }), []);
  const actor = useAppMachine({
    machine: ctx.machine,
    debugger: ctx.debugger
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const values = useMemo(() => {
    const query = unread ? NotificationReadState.Unread : NotificationReadState.Read;
    return {
      actor,
      query
    };
  }, []);
  return <AccountNotificationsContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="AccountNotificationsProvider" data-sentry-source-file="NotificationsContext.tsx">{children}</AccountNotificationsContext.Provider>;
}