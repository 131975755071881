import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { Box, NavLink } from '@mantine/core';
import { navButtonWrapper } from './styles.css';
export function NavLinkButton({
  onClick,
  label,
  isValid
}: {
  onClick: () => void;
  label: string;
  isValid?: boolean;
}) {
  return <Box className={navButtonWrapper} data-sentry-element="Box" data-sentry-component="NavLinkButton" data-sentry-source-file="NavLinkButton.tsx">
      <NavLink disabled={!isValid} px="md" label={label} rightSection={<ArrowRightCircleIcon height={40} width={40} />} variant="subtle" onClick={onClick} data-testid="onboarding-next" fw={700} data-sentry-element="NavLink" data-sentry-source-file="NavLinkButton.tsx" />
    </Box>;
}