import { devtoolsExchange } from '@urql/devtools'
import { type ClientOptions, type Exchange, createClient, debugExchange, fetchExchange, ssrExchange } from '@urql/next'
import type { Actor } from 'xstate'

import type { authMachine } from '~/machines'
import { isGraphqlDelayEnabled, publicRuntimeConfig } from '~/utils'

import {
  appCacheExchange,
  authContextExchange,
  authErrorExchange,
  delayExchange,
  errorNotificationExchange,
  opNameExchange,
  otelExchange,
  suspenseDedupExchange,
} from './exchanges'

export type UrqlClientOpts = {
  apiToken?: string
  extraExchanges?: Exchange[]
  authActor?: Actor<typeof authMachine>
  suspense?: boolean
}

const isSSR = typeof window === 'undefined'

function urqlClientConfig(opts: UrqlClientOpts = {}) {
  const url = publicRuntimeConfig.api.graphql
  if (!url) throw new Error('graphql client envvar is not set')
  const ssr = ssrExchange({ isClient: !isSSR })

  const config: ClientOptions = {
    url,
    exchanges: [
      !isSSR && devtoolsExchange,
      // suspenseDedupExchange,
      opNameExchange,
      otelExchange,
      // isTracingEnabled() && debugExchange,
      ...(opts.extraExchanges || []),
      // // persistedExchange({
      // //   preferGetForPersistedQueries: true,
      // // }),
      !isSSR && appCacheExchange(),
      !isSSR && authErrorExchange(opts),
      !isSSR && errorNotificationExchange(opts),
      // isGraphqlDelayEnabled() && delayExchange,
      // delayExchange,
      ssr,
      !isSSR && authContextExchange(opts),
      fetchExchange,
    ].filter(Boolean) as Exchange[],
    requestPolicy: 'cache-and-network',
    suspense: true, //opts.suspense,
  }
  return { config, ssr }
}

export function configureUrqlClient(opts: UrqlClientOpts = {}) {
  const { config, ssr } = urqlClientConfig(opts)
  const client = createClient(config)
  return { client, ssr }
}
