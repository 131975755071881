/* eslint-disable @typescript-eslint/no-require-imports */
export interface AppConfig {
  publicRuntimeConfig: AppPublicConfig
  serverRuntimeConfig: AppServerConfig
}

export interface AppPublicConfig {
  deployment: DEPLOYMENT
  nodeEnv: NodeEnvironment
  intlRoutes: Route[]
  logLevel: LogLevel
  api: AppAPIs
  auth0: Auth0Config
  hotjar: HotjarConfig
  maintenanceMode: MaintenanceModeConfig
  flagsmith: FlagsmithConfig
  sentry: {
    dsn: string
  }
}

export interface AppServerConfig {
  url: string
  host: HOST
  api: AppAPIs
}

export enum HOST {
  'colab.co' = 'colab.co',
  'createwithdome.com' = 'createwithdome.com',
}

export enum LOCALE {
  'en-US' = 'en-US',
}

export enum DEPLOYMENT {
  local = 'local',
  staging = 'staging',
  stable = 'stable',
}

export type NodeEnvironment = 'test' | 'development' | 'production'

export type LogLevel = 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR'

export interface Route {
  destination: string
  source: string
  locale: LOCALE
}

export interface AppAPIs {
  graphql: string
  companion: string
}

export interface Auth0Config {
  clientID: string
  domain: string
  scope: string
  audience: string
  apiDomain: string
}

export interface HotjarConfig {
  id: number
  sv: number
}

export interface MaintenanceModeConfig {
  enabled: boolean
  expectedReturn: Date
}

export interface FlagsmithConfig {
  clientSideKey: string
}

// we need this becase publicRuntimeConfig is considered "legacy"
// + it does not work well with appDir
// https://nextjs.org/docs/pages/api-reference/next-config-js/runtime-configuration
// https://github.com/vercel/next.js/issues/42065
// https://github.com/vercel/next.js/issues/39299
// https://github.com/vercel/next.js/issues/4024
// eslint-disable-next-line @typescript-eslint/no-var-requires

export const publicRuntimeConfig = require('./publicRuntimeConfig') as AppPublicConfig

export const serverRuntimeConfig = require('./serverRuntimeConfig') as AppServerConfig
