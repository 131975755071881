'use client';

import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Box, Burger, Drawer, Flex, Group, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation } from '@urql/next';
import { usePathname, useRouter } from 'next/navigation';
import { useContext, useEffect } from 'react';
import { MarkNotificationAsRead } from '~/app/_graphql/MarkNotificationAsRead';
import { AccountNav, AppLayoutContext, BurgerMenu, HeaderLogo, IdentityContext, NotificationsListComponent, WaitForStableAuthState, burgerContent, fullHeader, menuItems } from '../..';
import { NotificationsTabs } from '../../notifications/NotificationsTabs';
import { NotificationNav } from './NotificationNav';
import { burger, headerElement, headerLeftSectionBlock, headerLogoBlock, headerRightSectionBlock } from './styles.css';
import type { Route } from 'next';
function NonVisibleNotifications({
  shouldFetch
}: {
  shouldFetch: boolean;
}) {
  if (!shouldFetch) return;
  return <div style={{
    display: 'none'
  }} data-sentry-component="NonVisibleNotifications" data-sentry-source-file="Header.tsx">
      <NotificationsListComponent skipRendering={true} data-sentry-element="NotificationsListComponent" data-sentry-source-file="Header.tsx" />
    </div>;
}
function HeaderButton({
  path,
  label,
  testId
}: {
  path: Route;
  label: string;
  testId?: string;
}) {
  const pathName = usePathname();
  const router = useRouter();
  const {
    mobile
  } = useContext(AppLayoutContext);
  if (mobile) return null;
  return <Flex px="md" align="center" h="100%" className={headerElement} onClick={() => router.push(path)} data-sentry-element="Flex" data-sentry-component="HeaderButton" data-sentry-source-file="Header.tsx">
      <Flex pb="sm" style={{
      borderBottom: pathName === path ? '1px solid #000' : undefined
    }} data-sentry-element="Flex" data-sentry-source-file="Header.tsx">
        <Text size="lg" fw="600" pl="xs" tt="uppercase" data-testid={testId} lh="133.75%" data-sentry-element="Text" data-sentry-source-file="Header.tsx">
          {label}
        </Text>
      </Flex>
    </Flex>;
}
export function Header() {
  const {
    hiddenHeader,
    mobile
  } = useContext(AppLayoutContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const pathName = usePathname();
  const [openedBurger, {
    open: openBurger,
    close: closeBurger
  }] = useDisclosure(false);
  const [openedNotifications, {
    open: openNotifications,
    close: closeNotifications
  }] = useDisclosure(false);
  const [, update] = useMutation(MarkNotificationAsRead);
  useEffect(() => {
    closeNotifications();
  }, [pathName]);
  async function onCloseNotifications() {
    closeNotifications();
    await update({});
  }
  if (hiddenHeader) return null;
  return <Group className={fullHeader} px={mobile ? 'md' : '20px'} data-sentry-element="Group" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <Flex justify="space-between" w="100%" data-sentry-element="Flex" data-sentry-source-file="Header.tsx">
        <div className={headerLeftSectionBlock}>
          <>
            {mobile ? <Burger opened={openedBurger} onClick={openedBurger ? closeBurger : openBurger} data-testid="burger-button" classNames={{
            burger: burger
          }} style={{
            position: 'relative',
            bottom: '5px',
            paddingLeft: '0px'
          }} /> : null}
            <HeaderButton path="/" label="Community" data-sentry-element="HeaderButton" data-sentry-source-file="Header.tsx" />
            <HeaderButton path="/projects" label="Projects" testId="projects-header-link" data-sentry-element="HeaderButton" data-sentry-source-file="Header.tsx" />
          </>
        </div>
        <div className={headerLogoBlock}>
          <HeaderLogo data-sentry-element="HeaderLogo" data-sentry-source-file="Header.tsx" />
        </div>
        <div className={headerRightSectionBlock}>
          <Box className={menuItems} data-sentry-element="Box" data-sentry-source-file="Header.tsx">
            {/* <HeaderButton path="/gallery" label="Gallery" /> */}

            {/* <WaitForStableAuthState context="nav">
              {currentAccount ? <HeaderButton path="/contacts" label="Contacts" /> : null}
             </WaitForStableAuthState> */}

            <div style={{
            position: 'relative',
            bottom: '5px',
            borderBottom: pathName.includes(`accounts/${currentAccount?.id}`) ? '1px solid #000' : undefined
          }}>
              <AccountNav data-sentry-element="AccountNav" data-sentry-source-file="Header.tsx" />
            </div>
            <div style={{
            position: 'relative',
            bottom: '3px'
          }}>
              <NotificationNav openedNotifications={openedNotifications} closeNotifications={onCloseNotifications} openNotifications={openNotifications} data-sentry-element="NotificationNav" data-sentry-source-file="Header.tsx" />
            </div>
          </Box>
          <WaitForStableAuthState context="non-vis-comp" data-sentry-element="WaitForStableAuthState" data-sentry-source-file="Header.tsx">
            <NonVisibleNotifications shouldFetch={!!currentAccount} data-sentry-element="NonVisibleNotifications" data-sentry-source-file="Header.tsx" />
          </WaitForStableAuthState>
        </div>
      </Flex>

      <Drawer opened={openedBurger} onClose={closeBurger} closeOnClickOutside padding="none" keepMounted overlayProps={{
      backgroundOpacity: 0.8,
      blur: 0.9,
      color: 'black'
    }} closeButtonProps={{
      size: 'xl'
    }} withCloseButton={false} classNames={{
      body: burgerContent
    }} data-sentry-element="Drawer" data-sentry-source-file="Header.tsx">
        <Drawer.CloseButton size="xl" m="md" ml="xl" style={{
        position: 'relative',
        bottom: '10px',
        right: mobile ? '20px' : '15px',
        marginBottom: '20px'
      }} data-sentry-element="unknown" data-sentry-source-file="Header.tsx" />
        <BurgerMenu close={closeBurger} data-sentry-element="BurgerMenu" data-sentry-source-file="Header.tsx" />
      </Drawer>

      <Drawer opened={openedNotifications} withCloseButton={false}
    // title={
    //   <Flex align="center" gap="sm" pl="-10px" onClick={onCloseNotifications}>
    //     {/* <ChevronLeftIcon height={30} style={{ cursor: 'pointer' }} data-testid="close-notifications-button" /> */}
    //     <Title order={2} fw={700}>
    //       Notifications
    //     </Title>
    //   </Flex>
    // }
    onClose={onCloseNotifications}
    // overlayProps={{ backgroundOpacity: 0.8, blur: 0.9, color: 'black' }}
    position="right"
    // closeButtonProps={{ size: 'lg' }}
    classNames={{
      body: burgerContent
    }} style={{
      maxWidth: '100vw'
    }} styles={{
      content: {
        marginTop: '70px' // Adds space inside the drawer content to prevent overlap with header
      },
      overlay: {
        marginTop: '70px' // Adds space inside the drawer content to prevent overlap with header
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="Header.tsx">
        <NotificationsTabs data-sentry-element="NotificationsTabs" data-sentry-source-file="Header.tsx" />
      </Drawer>
    </Group>;
}