import { contextExchange } from '@urql/exchange-context'
import type { Exchange } from '@urql/next'
import { produce } from 'immer'

import type { UrqlClientOpts } from '../browserClient'

export function authContextExchange(opts: UrqlClientOpts): Exchange {
  return contextExchange({
    getContext: (operation) => {
      const token = opts.apiToken ? opts.apiToken : opts.authActor?.getSnapshot()?.context?.accessToken.value
      if (token) {
        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {}
        const authorization = `Bearer ${token}`
        return produce(operation.context, (ctx) => {
          ctx.fetchOptions = () => ({
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              authorization,
            },
          })
        })
      }
      return operation.context
    },
  })
}
