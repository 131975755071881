import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Text, useMantineTheme } from '@mantine/core';
import { useContext } from 'react';
import { ProfessionsFilterContext } from '~/app/(accounts)/_components/ProfessionsFilterContext';
import { AppLayoutContext, RemoteConfigContext } from '~/app/_components';
export function ProfessionsFilter() {
  const {
    professions: allProfessions
  } = useContext(RemoteConfigContext);
  const {
    professions,
    setProfessions
  } = useContext(ProfessionsFilterContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const theme = useMantineTheme();
  return <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    gap: '3px',
    justifyContent: 'space-between'
  }} data-sentry-component="ProfessionsFilter" data-sentry-source-file="ProfessionsFilter.tsx">
      {allProfessions.map((p, i) => {
      const isSelected = professions.includes(p.id);
      return (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        key={i} style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid black',
          width: mobile ? '48%' : '48%',
          marginBottom: '4px',
          padding: '5px',
          paddingLeft: '10px',
          fontWeight: '600',
          borderRadius: '8px',
          cursor: 'pointer',
          backgroundColor: isSelected ? theme.colors.brand[4] : 'white'
        }} onClick={() => {
          const toAdd = !professions.includes(p.id);
          const newProfessions = toAdd ? [...professions, p.id] : professions.filter(id => id !== p.id);
          setProfessions(newProfessions);
        }}>
            <Text size="md" fw={600}>
              {p.name}
            </Text>
            {isSelected ? <CheckIcon height={20} fontWeight={600} /> : <PlusIcon height={20} fontWeight={600} />}
          </div>
      );
    })}
    </div>;
}