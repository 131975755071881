'use client';

import { Grid } from '@mantine/core';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { AccountProfileCard } from '../AccountProfileCard';
import { ScrollToTop } from '../ScrollToTopAffix';
import { TrimmedAccountFragment } from '~/generated/graphql';

// Inner and Outer components exist to make the testing with storybook easier
export function SearchResultPageOuterDesktop({
  children
}: {
  children: ReactNode;
}) {
  return <>
      <Grid px="100px" py="90px" gutter="45px" justify="flex-start" data-sentry-element="Grid" data-sentry-source-file="DesktopResults.tsx">
        {children}
      </Grid>
      <ScrollToTop data-sentry-element="ScrollToTop" data-sentry-source-file="DesktopResults.tsx" />
    </>;
}
export function SearchResultPageInnerDesktop({
  edges
}: {
  edges: {
    node: TrimmedAccountFragment;
    cursor: string;
  }[];
}) {
  const [_w, setW] = useState<number | undefined>(0);
  const cardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setW(cardRef.current?.clientWidth);
  }, [cardRef.current?.clientWidth]);
  return <>
      {edges.map(edge => <Grid.Col span={{
      xs: 6,
      sm: 6,
      md: 4,
      lg: 4,
      xl: 3,
      xxl: 2.3
    }} key={edge.cursor} pb="53px">
          <div ref={cardRef}>
            <AccountProfileCard account={edge.node} />
          </div>
        </Grid.Col>)}
    </>;
}