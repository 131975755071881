import { graphql } from '~/generated/gql'

export const SendNoFolioReminderEmail = graphql(/* GraphQL */ `
  mutation SendNoFolioReminderEmail($id: String!) {
    sendNoFolioReminderEmail(id: $id) {
      __typename
      ... on Account {
        ...TrimmedAccount
      }

      ... on Error {
        message
      }
    }
  }
`)

export const SendWelcomeEmailFromAdmin = graphql(/* GraphQL */ `
  mutation SendWelcomeEmailFromAdmin($id: String!) {
    sendWelcomeEmailFromAdmin(id: $id) {
      __typename
      ... on Account {
        ...TrimmedAccount
      }

      ... on Error {
        message
      }
    }
  }
`)
