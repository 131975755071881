'use client';

import { useRouter } from 'next/navigation';
import type React from 'react';
import { createContext } from 'react';
import type { ContactInfo, TrimmedAccountFragment, TrimmedAccountWithContactInfoFragment, User } from '~/generated/graphql';
import { accountRoute } from '~/utils';
export type PeerAccountContextType = {
  account: TrimmedAccountFragment | TrimmedAccountWithContactInfoFragment;
  navigateToProfile: (e: any) => void;
};
export const PeerAccountContext = createContext<PeerAccountContextType>({} as PeerAccountContextType);
export function hasAccountContactInfo(account: TrimmedAccountFragment | TrimmedAccountWithContactInfoFragment): account is TrimmedAccountWithContactInfoFragment & {
  contactInfo: ContactInfo;
} {
  return !!(account as TrimmedAccountWithContactInfoFragment).contactInfo;
}
export function PeerAccountProvider({
  account,
  children
}: React.PropsWithChildren & {
  account: TrimmedAccountFragment;
}) {
  const router = useRouter();
  const routeInfo = {
    show: accountRoute(account.id).show,
    connect: accountRoute(account.id).connect
  };
  function navigateToProfile(e: any) {
    if (e.target && e.target.tagName !== 'IMG' && e.target.tagName !== 'P' && e.target.tagName !== 'H6' && e.target.tagName !== 'SPAN' && e.target.tagName !== 'H1') return;
    router.push(routeInfo.show);
  }
  return <PeerAccountContext.Provider value={{
    account,
    navigateToProfile
  }} data-sentry-element="unknown" data-sentry-component="PeerAccountProvider" data-sentry-source-file="PeerAccountContext.tsx">{children}</PeerAccountContext.Provider>;
}