/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
'use client';

import { Modal, Text, Textarea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@urql/next';
import { usePathname } from 'next/navigation';
import { useContext, useState } from 'react';
import { AppLayoutContext, IdentityContext, StyledButton } from '~/app/_components';
import { SendMessage } from '~/app/_graphql';
import { appTrace, sendUserInteractionsTo } from '~/utils';
export function ContactUs() {
  const [opened, {
    close,
    open
  }] = useDisclosure(false);
  const [feedback, setFeedback] = useState<string | undefined>('');
  const {
    currentAccount
  } = useContext(IdentityContext);
  const pn = usePathname();
  const {
    mobile
  } = useContext(AppLayoutContext);
  const [, executeSendMessage] = useMutation(SendMessage);
  async function sendFeedback() {
    const result = await executeSendMessage({
      recipientId: sendUserInteractionsTo,
      message: {
        content: `Feedback: ${feedback}. Account: ${currentAccount?.id}. Route: ${pn}`
      }
    });
    if (result.data?.sendMessage?.__typename === 'Account') {
      close();
      notifications.show({
        title: 'Feedback Sent',
        message: 'Thank you! We appreciate your feedback.'
      });
    } else if (result?.data?.sendMessage?.__typename === 'Error') {
      appTrace('Could not accept the friendship request', {
        result
      });
      notifications.show({
        title: 'Something went wrong, try again or contact us at hello@createwithdome.com',
        message: result.data.sendMessage.message,
        color: 'warning'
      });
    }
  }
  if (mobile) return null;
  return <>
      <div style={{
      zIndex: '100',
      position: 'fixed',
      bottom: mobile ? '160px' : '90px',
      right: '0px',
      color: '#48FFB9',
      cursor: 'pointer',
      height: '140px',
      width: mobile ? '30px' : '40px',
      backgroundColor: 'black',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bolder',
      fontSize: '12px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    }} onClick={open}>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          D
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          O
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          M
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.8'
      }}>
          E
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          &nbsp;
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          F
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          E
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          E
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          D
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          B
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          A
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          C
        </span>
        <span style={{
        transform: 'rotate(-90deg)',
        display: 'block',
        lineHeight: '0.7'
      }}>
          K
        </span>
      </div>

      <Modal opened={opened} onClose={close} size="auto" title="Feedback" radius="lg" data-sentry-element="Modal" data-sentry-source-file="ContactUs.tsx">
        <Text c="dimmed" data-sentry-element="Text" data-sentry-source-file="ContactUs.tsx">Ideas for Dome?</Text>
        <Text c="dimmed" data-sentry-element="Text" data-sentry-source-file="ContactUs.tsx">Is something bugging or too slow?</Text>
        <Text c="dimmed" data-sentry-element="Text" data-sentry-source-file="ContactUs.tsx">Let us know below!</Text>
        <Textarea py="md" minRows={10} onChange={e => setFeedback(e.target.value)} data-sentry-element="Textarea" data-sentry-source-file="ContactUs.tsx" />
        <StyledButton text="Submit" type="primary" width="100%" onClick={sendFeedback} data-sentry-element="StyledButton" data-sentry-source-file="ContactUs.tsx" />
      </Modal>
    </>;
}