import { notifications } from '@mantine/notifications'
import { captureException } from '@sentry/nextjs'
import { type Exchange, mapExchange } from '@urql/next'

import type { UrqlClientOpts } from '../browserClient'
import { getQueryNames } from './opNameExchange'

export function errorNotificationExchange(_opts: UrqlClientOpts): Exchange {
  return mapExchange({
    onError: ({ graphQLErrors, networkError }, op) => {
      if (graphQLErrors?.length) {
        graphQLErrors.map(({ message, extensions }) => {
          const code = extensions?.code as string
          const text = code ? `${code}: ${message}` : message

          notifications.show({
            id: 'api-error',
            title: `API Error: ${getQueryNames(op.query).join(',')}`,
            message: text,
            color: 'red',
          })
          captureException(message, extensions)
        })
      } else if (networkError) {
        notifications.show({
          id: 'network-error',
          title: 'Network Error',
          message: networkError.message,
          color: 'red',
        })
        captureException(networkError)
      }
    },
  })
}
