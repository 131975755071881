import { graphql } from '~/generated/gql'

export const VerifyInstagram = graphql(/* GraphQL */ `
  mutation updateCurrentAccount($account: AccountUpdateInput!) {
    updateCurrentAccount(account: $account) {
      ... on Account {
        id
        state
        socialInfo {
          igHandle
        }
      }
      ... on Error {
        message
      }
    }
  }
`)
