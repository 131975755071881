module.exports = {
  url: process.env.URL,
  host: process.env.HOST,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  api: {
    graphql: process.env.SSR_GRAPHQL_END_POINT,
    companion: process.env.NEXT_PUBLIC_COMPANION_END_POINT,
  },
}
