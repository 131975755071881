import { produce } from 'immer'
import { type Actor, assign, createMachine, raise } from 'xstate'

import { type RelayConnectionContext, type RelayConnectionEvents, initRelayConnectionContext } from '.'
import { MachineDebugger } from '../common/debugger'

export type RelayConnectionMachine = ReturnType<typeof mkRelayConnectionMachineCtx>['machine']
export type RelayConnectionActor = Actor<RelayConnectionMachine>

type RelayConnectionServices = {
  dummy: { output: { token: string } }
}

export function mkRelayConnectionMachineCtx({ id = 'relay' }: { id: string }) {
  const md = new MachineDebugger()
  const machine = createMachine(
    {
      types: {
        // typegen: {} as any,
        context: {} as RelayConnectionContext,
        events: {} as RelayConnectionEvents,
        //  actors: {} as SearchServices,
      },
      context: initRelayConnectionContext(),
      id,
      type: 'parallel',
      states: {
        pages: {
          initial: 'loading',
          on: {
            UPDATE_PAGE_INFO: {
              actions: [
                md.logAction('updatePageInfo received'),
                'updatePageInfo',
                md.logAction('updatePageInfo received2'),
                'reportPageInfoUpdate',
                md.logAction('updatePageInfo received3'),
              ],
            },
            // TODO can arrive with old results after query change?
            PAGE_INFO_UPDATED: [
              {
                description: 'Cursor is available, start fetching next page if needed',

                guard: 'allowNeededNextPageFetch',
                actions: [md.logAction('page_info_updated'), 'unsetNeedsNextPage', 'fetchNextPage'],
              },
              { actions: [md.logAction('unhandled page_info_updated')] },
            ],
            // TODO maybe need to force assignment first?
            RESET: {
              actions: [md.logAction('updateQuery received'), 'updateSearchQuery'],
              target: '.loading',
            },
          },
          states: {
            loading: {
              always: [
                {
                  guard: 'isFetchingFinished',
                  target: 'done',
                },
              ],
              on: {
                NEXT_PAGE: [{ guard: 'nextPageFetchable', actions: 'fetchNextPage' }, { actions: 'setNeedsNextPage' }],
              },
            },

            done: {
              on: {
                NEXT_PAGE: {
                  target: 'loading',
                  guard: 'nextPageFetchable',
                  actions: 'fetchNextPage',
                },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        reportPageInfoUpdate: raise({ type: 'PAGE_INFO_UPDATED' }),
        fetchNextPage: assign(({ context }) =>
          produce(context, (ctx) => {
            const lastPage = maxPage(context.pageArgsList)
            md.consoleLog(`fetching next page: ${lastPage + 1}`)
            if (!ctx.pageInfoList[lastPage]) {
              md.consoleLog('fetch next page is received but no page info yet')
            } else {
              const lastCursor = ctx.pageInfoList[lastPage].endCursor!
              ctx.pageArgsList[lastPage + 1] = {
                after: lastCursor,
                first: ctx.resultsPerPage,
              }
            }
          })
        ),

        updatePageInfo: assign(({ context, event }) =>
          produce(context, (ctx) => {
            if (event.type !== 'UPDATE_PAGE_INFO') return
            md.consoleLog(`updatePageInfo, received ${JSON.stringify(event)}`)
            ctx.pageInfoList[event.pageNumber] = event.pageInfo
            ctx.totalCount = event.totalCount
            ctx.lastLoadedPage = maxPage(ctx.pageInfoList)
            md.consoleLog(
              `updatePageInfo, produced: PI list ${JSON.stringify(ctx.pageInfoList)}, PA list ${JSON.stringify(
                ctx.pageArgsList
              )}`
            )
          })
        ),

        setNeedsNextPage: assign(({ context }) =>
          produce(context, (ctx) => {
            ctx.needsNextPage = true
          })
        ),
        unsetNeedsNextPage: assign(({ context }) =>
          produce(context, (ctx) => {
            ctx.needsNextPage = false
          })
        ),
        updateSearchQuery: assign(({ context, event }) =>
          produce(context, (ctx) => {
            if (event.type !== 'RESET') return
            Object.assign(ctx, initRelayConnectionContext())
          })
        ),
      },

      guards: {
        isFetchingFinished: ({ context }) => allArgsLoaded(context) && !context.needsNextPage,
        allowNeededNextPageFetch: ({ context }) => {
          md.consoleLog('allowNeededNextPageFetch guard')
          return allArgsLoaded(context) && context.needsNextPage && nextPageFetchable(context)
        },
        nextPageFetchable: ({ context }) => {
          md.consoleLog('nextPageFetchable guard')
          return nextPageFetchable(context)
        },
      },

      actors: {},
    }
  )

  function nextPageFetchable(context: RelayConnectionContext) {
    return context.lastLoadedPage ? context.pageInfoList[context.lastLoadedPage].hasNextPage : false
  }

  function allArgsLoaded(context: RelayConnectionContext) {
    const infoPages = Object.keys(context.pageInfoList)
    const argsPages = Object.keys(context.pageArgsList)

    // md.consoleLog(
    //   'allArgsLoaded ' +
    //     JSON.stringify(infoPages) +
    //     ' ' +
    //     JSON.stringify(argsPages) +
    //     ' ' +
    //     JSON.stringify(context.needsNextPage) +
    //     ' ' +
    //     JSON.stringify(nextPageFetchable(context)),
    // )
    return argsPages.every((argPage) => infoPages.includes(argPage))
  }

  function maxPage(record: Record<number, any>): number {
    let maxKey: number | undefined = undefined

    for (const key in record) {
      const numericKey = Number.parseFloat(key)
      if (!Number.isNaN(numericKey)) {
        if (maxKey === undefined || numericKey > maxKey) {
          maxKey = numericKey
        }
      }
    }

    return maxKey || 0
  }

  return { machine, debugger: md }
}
