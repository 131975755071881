import { useActorRef } from '@xstate/react';
import { type Actor, type ActorOptions, type AnyActorLogic, createActor } from 'xstate';
import { MachineDebugger } from './debugger';
import { appTrace } from '~/utils';
const isSSR = typeof window === 'undefined' && !process.env.VITEST;
export function useAppMachine<TMachine extends AnyActorLogic>(args: {
  machine: TMachine;
  debugger?: MachineDebugger;
  preStart?: (actor: Actor<TMachine>) => void;
}) {
  const dbg = args.debugger || new MachineDebugger();
  const actor = useActorRef(args.machine, {
    inspect: dbg.unifiedInspect
  });
  if (isSSR) return actor;
  dbg.start(actor);
  // WARNING:
  // this is executed before actor.start() inside of the useEffect
  // needed to initialise subscription/dependencies
  // TODO don't run preStart multiple times
  if (args.preStart) args.preStart(actor);
  // No need to start the actor - it's started inside of useEffect in useActorRef
  return actor;
}

// needed only for testing
export function startAppMachine<TMachine extends AnyActorLogic>(args: {
  machine: TMachine;
  debugger?: MachineDebugger;
  preStart?: (actor: Actor<TMachine>) => void;
}) {
  const dbg = args.debugger || new MachineDebugger();
  const options: ActorOptions<TMachine> = {
    inspect: dbg.unifiedInspect
  };
  const actor = createActor(args.machine, options);
  if (isSSR) {
    appTrace('not starting auth actor in SSR');
    return actor;
  }
  dbg.start(actor);
  // WARNING:
  // this is executed before actor.start() inside of the useEffect
  // needed to initialise subscription/dependencies
  if (args.preStart) args.preStart(actor);
  actor.start();
  return actor;
}