'use client';

import { useSelector } from '@xstate/react';
import { useEffect, useRef } from 'react';
import type { Actor, AnyStateMachine, StateFrom } from 'xstate';
type Resolver = (value: undefined) => void;
type EmptyPromise = Promise<undefined>;
type SuspenseState<TResult> = {
  promise: EmptyPromise;
  resolve: Resolver;
  status: 'loading';
  result: null;
} | {
  promise: EmptyPromise;
  resolve: Resolver;
  status: 'resolved';
  result: TResult;
};

//https://prismic.io/blog/what-is-react-suspense
export interface Resource<TResult> {
  read(): never | TResult;
}
export function useSuspensefulGetter<TMachine extends AnyStateMachine, TGetterReturn>(state: Actor<TMachine>,
// state: StateFrom<TMachine>,
condition: (state: StateFrom<TMachine>) => boolean, getter: (state: StateFrom<TMachine>) => TGetterReturn): Resource<TGetterReturn> {
  let resolveValue: Resolver;
  const machineState = useSelector(state, s => s);
  const promise: EmptyPromise = new Promise(resolve => {
    resolveValue = resolve;
  });
  const ref = useRef<SuspenseState<TGetterReturn>>({
    promise,
    // @ts-ignore
    resolve: resolveValue,
    status: 'loading',
    result: null
  });
  useEffect(() => {
    if (ref.current.status === 'resolved') {
      ref.current.result = getter(machineState);
    }
  }, [ref.current.status, machineState, getter]);
  if (condition(machineState)) {
    ref.current.status = 'resolved';
    ref.current.result = getter(machineState);
    ref.current.resolve(undefined);
  }
  return {
    read(): never | TGetterReturn {
      switch (ref.current.status) {
        case 'resolved':
          return ref.current.result;
        default:
          {
            throw ref.current.promise;
          }
      }
    }
  };
}