import type { Route } from 'next'

export const profileTabs = ['profile', 'folio', 'contact']
export const defaultProfileTab = profileTabs[0]

export function meRoute(): Record<string, Route> {
  return {
    editProfile: '/profile' as Route,
  }
}

export function editProfileTabRoute(tab: string | null) {
  if (!tab) return meRoute().editProfile
  const newTab = profileTabs.includes(tab) ? tab : defaultProfileTab
  if (newTab === defaultProfileTab) {
    return meRoute().editProfile
  }
  return `/profile/${newTab}` as Route
}
